import React from 'react';
import styles from './style.module.scss';
import { IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import Instagram from '@mui/icons-material/Instagram';
import Telegram from '@mui/icons-material/Telegram';
import cx from 'classnames';
import IconDiscord from 'assets/icons/iconDiscord';
import { handleOpenPlatform } from 'utils';
import { Link } from 'react-router-dom';

function index({ display, className }) {
  return (
    <div
      className={cx(
        styles.footer,
        display && styles.showFooter,
        className && className
      )}
    >
      <div className={styles.left}>© 2024 Minti. All rights reserved.</div>
      <div className={styles.center}>
        <div className={styles.social}>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('twitter')}
          >
            <XIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('instagram')}
          >
            <Instagram />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('discord')}
          >
            <IconDiscord />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenPlatform('telegram')}
          >
            <Telegram />
          </IconButton>
        </div>
        <div className={styles.policy}>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/refund-dispute-policy">Refund and Dispute Policy</Link>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.information}>admin@minti.fun</div>
      </div>
    </div>
  );
}

export default index;
