import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useApi } from 'api';
import Loader from 'react-loader-spinner';
import { ClipLoader } from 'react-spinners';

import { useSelector } from 'react-redux';

import {
  Grid,
  Backdrop,
  styled,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import Header from 'components/header';
import styles from '../styles.module.scss';
import { AppRegistrationOutlined } from '@mui/icons-material';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';

import Carousel from 'components/Carousel';
import { getContractTypeById } from 'constants/contracts.constants';

import cx from 'classnames';
import { Description, PropertyItem } from '../components';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  NFT_STATUSES,
  NFT_STATUSES_CODES,
} from 'constants/NFTStatuses.constants';
import { delayRun, uuidv4 } from 'utils';
import showToast from 'utils/toast';

import IconLoading from 'assets/icons/iconLoading';
import CarouselModal from 'components/CarouselModal';
import { ASSET_TYPES } from 'constants/asset.constants';

const STATUS_ID_DEFAULT_VALUE = -1;

const BEFORE_MINT_INFO_MESSAGE =
  'The ownership of your asset has been verified and is ready to be minted.\n' +
  'Please proceed by approving the transaction below.';

const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'rgba(35, 35, 52, 0.9)',
  zIndex: 9999,
}));
const index = () => {
  const { id: tokenID } = useParams();
  const history = useHistory();
  const { authToken, user } = useSelector(state => state.Auth);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [assets, setAssets] = useState([]);
  const [posterUrl, setPosterUrl] = useState();
  const [promo, setPromo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waitingGaiaResp, setWaitingGaiaResp] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const [createdNft, setCreatedNft] = useState({});
  const [statusId, setStatusId] = useState(STATUS_ID_DEFAULT_VALUE);
  const [processing, setProcessing] = useState(null);
  const [sourceFileURL, setSourceFileURL] = useState();
  const [showSourceFile, setShowSourceFile] = useState(true);
  const { fetchCreatedNft, fetchNftStatus, mintNft } = useApi();

  const contractType = createdNft?.contractType
    ? getContractTypeById(createdNft?.contractType?.contractTypeId)
    : undefined;

  const navigateNFT = async name => {
    setLoading(true);
    await delayRun(() => {
      setLoading(false);
    }, 5000);
    if (!name) {
      const response = await fetchCreatedNft(authToken, tokenID);
      if (response.data && Object.keys(response.data).length > 0) {
        const nft = response.data;
        history.push(
          `/explore/${nft.name.toLowerCase().replaceAll(' ', '+')}`,
          {
            from: 'pending',
          }
        );
      } else {
        history.replace('/404');
      }
    } else {
      history.push(`/explore/${name.toLowerCase().replaceAll(' ', '+')}`, {
        from: 'pending',
      });
    }
  };

  const getNftStatus = async (statusId, processing) => {
    const response = await fetchNftStatus(authToken, tokenID);
    if (response.errors) {
      console.log(response.errors);
    } else if (Object.keys(response.data).length > 0) {
      const data = response.data;
      const newStatusId = data.status.nftStatusId;
      const newProcessing = data.processing;
      if (newStatusId !== statusId) {
        if (newStatusId === NFT_STATUSES_CODES.MINTED) {
          await asyncNft();
        }
        setStatusId(newStatusId);
      }

      if (newProcessing !== processing) {
        if (newProcessing !== 'pending') {
          await asyncNft();
        }
        setProcessing(newProcessing);
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      getNftStatus(statusId, processing);
      const checkNftStatus = setInterval(() => {
        try {
          getNftStatus(statusId, processing);
        } catch (e) {
          console.log("Couldn't get Asset status: ", e);
        }
      }, 1000 * 3);
      return () => clearInterval(checkNftStatus);
    }
  }, [statusId, processing, loading]);

  const asyncNft = async () => {
    setLoading(true);

    const response = await fetchCreatedNft(authToken, tokenID);

    if (response.data && Object.keys(response.data).length > 0) {
      const nft = response.data;
      if (nft.user.userId !== user.userId) {
        history.replace('/404');
      }
      if (nft.nftStatusId === NFT_STATUSES_CODES.PREMINT) {
        history.push(`/draft/${nft.nftId}`, { from: 'explore' });
      }
      if (nft.nftStatusId === NFT_STATUSES_CODES.MINTED) {
        await navigateNFT(nft.name);
      }
      const tempAsset = [];
      const sourceFile = [];
      let watermarkedThumbnailURL = '';
      let selectedImage;
      for (let i = 0; i < nft.nftAssets.length; i++) {
        const asset = nft.nftAssets[i];
        switch (asset.assetType.name) {
          case ASSET_TYPES.PHOTOS:
            tempAsset.push(asset.url);
            break;
          case ASSET_TYPES.THUMBNAIL_IMAGE:
            selectedImage = asset.parentId;
            break;
          case ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE:
            watermarkedThumbnailURL = asset.url;
            break;
          case ASSET_TYPES.WATERMARKED_SOURCE_FILE:
            sourceFile.push(asset);
            break;
          case ASSET_TYPES.WATERMARKED_THUMBNAIL_VIDEO:
            sourceFile.push(asset);
            break;
        }
      }

      if (sourceFile.length > 0 && selectedImage) {
        const foundChosenImageIndex = sourceFile.findIndex(
          el => el.parentId === selectedImage
        );
        const chosenFile = sourceFile[foundChosenImageIndex];
        sourceFile.splice(foundChosenImageIndex, 1);
        watermarkedThumbnailURL = chosenFile.url;
      }

      const sourceFileURL = sourceFile.map(item => item.url);
      setSourceFileURL(sourceFileURL);

      setPosterUrl(watermarkedThumbnailURL);

      setPromo(tempAsset);

      setAssets(
        !watermarkedThumbnailURL
          ? [...sourceFileURL]
          : [watermarkedThumbnailURL, ...sourceFileURL]
      );
      setCreatedNft(nft);
      setProcessing(nft.processing);
      setStatusId(nft.nftStatusId);
    } else {
      history.replace('/404');
    }
    setLoading(false);
  };

  const toggleShowSourceFile = async () => {
    setAssets(
      showSourceFile
        ? [...promo]
        : [...(posterUrl ? [posterUrl] : []), ...sourceFileURL]
    );
    setShowSourceFile(prev => !prev);
  };

  useEffect(() => {
    asyncNft();
  }, [authToken, tokenID]);

  const mintNFTHandler = async () => {
    setWaitingGaiaResp(true);
    try {
      const { data } = await mintNft(authToken, createdNft.nftId);
      console.log('data', data);
      if (data && data.message === 'OK') {
        showToast('success', `Hold tight...`);
        await getNftStatus();
      } else {
        showToast('error', 'There is an issue while minting, please try again');
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'There is an issue while minting, please try again');
    } finally {
      setWaitingGaiaResp(false);
    }
  };

  const renderFiles = files => {
    return files?.map(file => {
      return (
        <a
          href={file.url}
          key={file.asset_id}
          className={styles.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={pdfPreviewIcon}
            alt={file.name}
            className={styles.documentIcon}
          />
        </a>
      );
    });
  };

  const renderStatusContent = () => {
    if (waitingGaiaResp) {
      return (
        <div className={styles.loadingIndicator}>
          <ClipLoader color="var(--primary-color)" size={25} />
        </div>
      );
    }
    let content = (
      <div className={styles.statusTitle}>
        <span>Status:</span>{' '}
        {NFT_STATUSES[Object.keys(NFT_STATUSES_CODES)[statusId - 1]]}
      </div>
    );
    if (statusId === NFT_STATUSES_CODES.APPROVED_BY_ADMIN) {
      content = (
        <StyledButton onClick={async () => await mintNFTHandler()}>
          Mint it
        </StyledButton>
      );
    }
    return content;
  };
  return (
    <div className={styles.container}>
      <Header border />
      <StyledBackdrop open={loading}>
        <Loader
          type="Oval"
          color="var(--primary-color)"
          height={50}
          width={50}
        />
      </StyledBackdrop>
      {Object.keys(createdNft).length > 0 && (
        <Grid container className={cx(styles.body)}>
          <>
            <Grid item xs={12} md={8} className={styles.thumbnailContainer}>
              {assets.length > 0 ? (
                <Carousel
                  items={assets}
                  isMobile={isMobile}
                  posterUrl={posterUrl}
                  setCarouselIndex={setCarouselIndex}
                />
              ) : (
                <div className={styles.carouselContainer}>
                  <ClipLoader color="var(--primary-color)" size={40} />
                </div>
              )}
              <div className={styles.ad}>Advertisement</div>
            </Grid>
            <Grid item xs={12} md={4} className={styles.details}>
              <div className={styles.title}>{createdNft?.name}</div>
              {processing === 'pending' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box fontSize="13px" color="var(--primary-color)">
                    Your Asset is processing
                  </Box>
                  <IconLoading />
                </Box>
              )}
              <div className={styles.status}>
                <div className={styles.statusMessage}>
                  {statusId === NFT_STATUSES_CODES.APPROVED_BY_ADMIN
                    ? BEFORE_MINT_INFO_MESSAGE
                    : ''}
                </div>

                {renderStatusContent()}
              </div>
              <Description description={createdNft?.description} />
              <div className={styles.scrollContainer}>
                <div className={styles.scrollContent}>
                  <PropertyItem
                    title={
                      <>
                        <AppRegistrationOutlined />
                        Registry
                      </>
                    }
                  >
                    <Grid container className={styles.itemsList}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={styles.documentContainer}
                      >
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>Category</div>
                          <div className={styles.registryValue}>
                            {createdNft?.category.type}
                          </div>
                        </div>
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Subcategory
                          </div>
                          <div
                            className={cx(
                              styles.registryValue,
                              styles.tagValue
                            )}
                          >
                            {createdNft?.subcategories &&
                              createdNft?.subcategories.map((item, index) => (
                                <div className={styles.tagItem} key={index}>
                                  {item.type}
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>Tags</div>
                          <div
                            className={cx(
                              styles.registryValue,
                              styles.tagValue
                            )}
                          >
                            {createdNft?.tags &&
                              createdNft?.tags.map(item => (
                                <Link
                                  to={`/search/${item.name}`}
                                  className={styles.tagItem}
                                  key={item.tagId}
                                >
                                  {item.name}
                                </Link>
                              ))}
                          </div>
                        </div>
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Asset Owner is Licensed Under
                          </div>
                          <div className={styles.registryValue}>
                            {createdNft?.contractType
                              ? createdNft.contractType.name
                              : ''}
                          </div>
                        </div>
                        {
                          <div className={styles.documentPaddings}>
                            <div className={styles.registryLabel}>
                              Artist Name
                            </div>
                            <div className={styles.registryValue}>
                              {createdNft?.artist
                                ? createdNft?.artist?.name
                                : '---'}
                            </div>
                          </div>
                        }
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>Royalty</div>
                          <div className={styles.registryValue}>
                            {createdNft?.royalty}%
                          </div>
                        </div>
                        {createdNft?.artist?.website &&
                          createdNft?.artist?.website !== 'undefined' && (
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Artist Website
                              </div>
                              <div className={styles.registryValue}>
                                {createdNft?.artist?.website ? (
                                  <a
                                    href={`https://${createdNft?.artist_website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.link}
                                  >
                                    {createdNft?.artist?.website}
                                  </a>
                                ) : (
                                  '---'
                                )}
                              </div>
                            </div>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={styles.documentContainer}
                      >
                        {contractType && (
                          <div className={styles.documentPaddings}>
                            <div className={styles.registryLabel}>
                              {contractType.label}
                            </div>
                            <div className={styles.registryValue}>
                              {renderFiles([
                                {
                                  url: contractType.url,
                                  asset_id: uuidv4(),
                                },
                              ])}
                            </div>
                          </div>
                        )}
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Model/Actor Releases
                          </div>
                          <div className={styles.registryValue}>
                            {createdNft?.actorModelReleases &&
                            createdNft?.actorModelReleases.length
                              ? renderFiles([createdNft?.actorModelReleases])
                              : 'No files attached.'}
                          </div>
                        </div>
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Additional Documents
                          </div>
                          <div className={styles.registryValue}>
                            {createdNft?.additionalDocuments &&
                            createdNft?.additionalDocuments.length
                              ? renderFiles(createdNft?.additionalDocuments)
                              : 'No files attached.'}
                          </div>
                        </div>
                        {posterUrl && promo.length > 0 && (
                          <StyledButton onClick={() => toggleShowSourceFile()}>
                            {!showSourceFile
                              ? 'See Assets'
                              : 'See Promo Content'}
                          </StyledButton>
                        )}
                      </Grid>
                    </Grid>
                  </PropertyItem>
                  <div className={styles.ad}>Advertisement</div>
                </div>
              </div>
            </Grid>
          </>
        </Grid>
      )}
      <CarouselModal items={assets} startIndex={carouselIndex} />
    </div>
  );
};

export default index;
