import React, { useState } from 'react';
import cx from 'classnames';
import { StyledButton } from 'components/StyledComponents';
import { Menu, darken } from '@mui/material';

import StatusFilter from 'components/StatusFilter';
import CategoriesFilter from 'components/CategoriesFilter';
import LicenseFilter from 'components/LicenseFilter';
import PaymentFilter from 'components/PaymentFilter';

const FiltersButton = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { className, categories, contractTypes } = props;

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton className={cx(className)} onClick={handleClick}>
        Filters
      </StyledButton>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 300,
            maxHeight: 400,
            border: '1px solid var(--light-border-color)',
            backgroundColor: 'var(--place-holder-color)',
            filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
            mt: 1,
            overflow: 'scroll',
            // p: 1,
            '& .MuiMenuItem-root': {
              mr: 1,
              ml: 1,
              borderRadius: 2,
              pt: 1.5,
              pb: 1.5,
              color: '#757575',
            },
            '& .selected': {
              bgcolor: '#f8f5fe',
              '&:hover': {
                bgcolor: darken('#f8f5fe', 0.05),
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StatusFilter />
        <CategoriesFilter categories={categories} />
        <PaymentFilter />
        <LicenseFilter contractTypes={contractTypes} />
      </Menu>
    </>
  );
};

export default FiltersButton;
