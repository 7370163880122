import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isZipFile } from 'utils';

export const useFileChecking = (frontCover, photos, checkedAssets) => {
  const [newMessages, setNewMessages] = useState({ errors: [], successes: [] });
  const processedAssetIdsRef = useRef(new Set());
  const failedAssetIdsRef = useRef(new Set());
  const generateErrorMessage = useCallback(
    (asset, isFrontCover) => {
      if (isFrontCover) {
        const sourceIsZip = isZipFile(frontCover?.at(0)?.url);
        if (sourceIsZip) {
          return `The source file "${asset.fileName}" contains one or more ${
            asset.virusDetected
              ? 'virus-infected items. Please replace the file.'
              : asset.nsfwDetected
              ? 'inappropriate items.'
              : asset.likelyDetected
              ? 'potentially inappropriate items. Please wait for review.'
              : 'corrupted items. Please replace the file.'
          }`;
        } else {
          return `The source file "${asset.fileName}" is${
            asset.virusDetected
              ? ' corrupted due to virus detection'
              : asset.nsfwDetected
              ? ' flagged for inappropriate content'
              : asset.likelyDetected
              ? ' flagged for potentially inappropriate content. Please wait for review.'
              : ' corrupted'
          }`;
        }
      } else {
        return `The promotional media file "${asset.fileName}" is${
          asset.virusDetected
            ? ' corrupted due to virus detection'
            : asset.nsfwDetected
            ? ' flagged for inappropriate content'
            : asset.likelyDetected
            ? ' flagged for potentially inappropriate content. Please wait for review.'
            : ' corrupted'
        }.`;
      }
    },
    [frontCover]
  );

  const frontCoverIds = useMemo(
    () => new Set(frontCover?.map(item => item.assetId) || []),
    [frontCover]
  );
  const photoIds = useMemo(
    () => new Set(photos?.map(item => item.assetId) || []),
    [photos]
  );

  useEffect(() => {
    if (!checkedAssets?.length) return;

    const newErrors = [];
    const newSuccesses = [];

    checkedAssets.forEach(asset => {
      if (!processedAssetIdsRef.current.has(asset.assetId)) {
        processedAssetIdsRef.current.add(asset.assetId);

        if (asset.status === 'checked') {
          if (asset.failed) {
            if (frontCoverIds.has(asset.assetId)) {
              newErrors.push(generateErrorMessage(asset, true));
            } else if (photoIds.has(asset.assetId)) {
              newErrors.push(generateErrorMessage(asset, false));
            }
            failedAssetIdsRef.current.add(asset.assetId);
          } else if (
            frontCoverIds.has(asset.assetId) ||
            photoIds.has(asset.assetId)
          ) {
            newSuccesses.push(`File saved`);
          }
        }
      } else if (
        failedAssetIdsRef.current.has(asset.assetId) &&
        !asset.failed
      ) {
        newSuccesses.push(`${asset.fileName} was approved`);
      }
    });

    if (newErrors.length > 0 || newSuccesses.length > 0) {
      setNewMessages({ errors: newErrors, successes: newSuccesses });
    }
  }, [checkedAssets, frontCoverIds, photoIds, generateErrorMessage]);

  return newMessages;
};
