import useContract from 'hooks/useContract';
import { TRANSFER_ABI } from './abi';
import { useCheckGasFee } from 'hooks/useCheckGasFee';
import { useAppKitAccount } from '@reown/appkit/react';
export const useDirectTransferContract = () => {
  const { checkGasFee } = useCheckGasFee();
  const { address: account } = useAppKitAccount();

  const { getContract } = useContract();
  const getTransferContract = async () => {
    return await getContract(
      process.env.REACT_APP_DIRECT_TRANSFER,
      TRANSFER_ABI
    );
  };

  const sendPayment = async (receiver, payToken, payment, tokenId) => {
    const contract = await getTransferContract();
    const args = [receiver, payToken, payment.toString(), tokenId];

    const estimation = await contract.estimateGas.sendPayment(...args);
    const isValid = await checkGasFee(estimation, account);

    if (!isValid) {
      return;
    }
    return await contract.sendPayment(...args);
  };

  return {
    sendPayment,
    getTransferContract,
  };
};
