import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createAppKit } from '@reown/appkit/react';
import { avalancheFuji } from '@reown/appkit/networks';
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5';

import './index.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from 'components/app';
import createStore from '../src/stores/reduxStore';
import { MoonPayProvider } from '@moonpay/moonpay-react';
// import DateFnsUtils from '@date-io/date-fns';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing//.fullstock.ai/
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/fs-staging-api\.ai\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { store, persistor } = createStore();
const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--background-color)',
          color: 'var(--primary-text-color)',
          border: '1px solid var(--border-color)',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          marginLeft: '30px !important',
          marginRight: '10px !important',

          '@media (max-width: 759px)': {
            marginLeft: '20px !important',
            marginRight: '10px !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.search-box-header .MuiInputBase-root': { height: 44 },
        },
        paper: {
          marginTop: 1,
          border: `1px solid var(--border-color)`,
          borderRadius: '12px',
          boxShadow: '0 0 5px 0px #dedede',
          backgroundColor: 'var(--background-color)',
          color: 'var(--primary-text-color) !important',
        },
        loading: {
          color: 'var(--primary-text-color) !important',
        },
        popper: {
          zIndex: 99999,
        },
        listbox: {
          padding: 0,
          li: {
            borderBottom: `1px solid var(--border-color)`,
            backgroundColor: 'var(--background-color)',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid var(--border-color)`,
          backgroundColor: `var(--background-color) !important`,
          paddingLeft: '30px',
          fontWeight: '500',
          color: '#a1a1a1',
        },
      },
    },
  },
});

// 1. Get projectId
const projectId = '72bd8549221ce053b005cd7aa3f3fea0';

// 2. Set the networks
const networks = [avalancheFuji];

// 4. Create a AppKit instance
createAppKit({
  adapters: [new Ethers5Adapter()],
  defaultNetwork: networks[0],
  networks,
  projectId,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    swaps: false,
    onramp: false,
    history: false,
  },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <MoonPayProvider apiKey={process.env.REACT_APP_MOON_PAY_API_KEY}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App fullScreen />
          </ThemeProvider>
        </PersistGate>
      </MoonPayProvider>
    </Provider>
  </HelmetProvider>
);
