import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { ASSETTYPES } from 'constants/types';
import { Box } from '@mui/material';
import NFTItem from 'components/NFTItem';
import GradientCircularProgress from '../GradientCircularProgress';
import { useAppKitAccount } from '@reown/appkit/react';
import { StyledButton } from 'components/StyledComponents';

import styles from './styles.module.scss';

const nothingToDisplay = () => (
  <div
    style={{
      width: '100%',
      textAlign: 'center',
      marginTop: '20px',
      color: 'var(--primary-text-color)',
      flex: '1',
    }}
  >
    Nothing to display yet
  </div>
);

function MasonaryNFTs({
  category = null,
  sortBy = 'createdAt',
  status = [],
  searchKey = null,
  license = null,
  paytoken = null,
  community = null,
  paymentMethod = null,
  setContainerLoading = null,
  userId = null,
  avoidNullUserId = false,
  isLandingPage = false,
  isOtherProfile = false,
  showNftStatus = false,
  hideSubItems = false,
  onlyFavorites = null,
  onlyLikes = null,
  onlyMinted = null,
  onInteractionsUpdated,
  setCount,
  toggleUserFollow,
  showLoadMoreButton = false,
  userName = null,
  itemsPerPage = 36,
  exceptNftId = null,
  openInNewTab = false,
}) {
  const observerRef = useRef(null);
  const start = useRef(0);
  const total = useRef(0);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [top, setTop] = useState(0);
  const { address: account } = useAppKitAccount();

  const {
    fetchNfts,
    toggleFavoriteItem,
    toggleLikeItem,
    followUser,
  } = useApi();

  const { user: me, authToken } = useSelector(state => state.Auth);
  const [nfts, setNFTs] = useState([]);

  const firstLoad = start.current === 0;

  const fetchNftsHandler = async refresh => {
    if (
      loading ||
      (avoidNullUserId && !userId && !onlyFavorites && !onlyLikes) ||
      (!refresh && start.current >= total.current)
    ) {
      setNoMoreData(true);
      return;
    }

    setNoMoreData(false);
    setLoading(true);

    if (firstLoad) {
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }

    try {
      const _start = refresh ? 0 : start.current;

      const { data } = await fetchNfts(authToken, {
        from: _start,
        count: itemsPerPage,
        sortby: sortBy,
        searchKey,
        userId,
        contractTypeId: license,
        paymentFilter: paymentMethod,
        categoryIdList: (() => {
          const categories = category?.map(cat => cat.categoryId);
          return categories?.length ? categories : undefined;
        })(),
        statusFilter: (() => {
          return status?.length ? status : undefined;
        })(),
        payTokenIdList: (() => {
          const list = paytoken?.map(token => token.payTokenId);
          return list?.length ? list : undefined;
        })(),
        communityIdList: (() => {
          const list = community?.map(com => com.payTokenId); // same as payTokenId
          return list?.length ? list : undefined;
        })(),
        onlyFavorites,
        onlyLikes,
        onlyMinted,
        exceptNftId,
      });
      setFetched(true);
      const formattedData = data.nfts.map(nft => {
        const mutipleSourceFiles = [
          ASSETTYPES.WATERMARKED_THUMBNAIL_VIDEO,
          ASSETTYPES.TRIMMED_AUDIO,
          ASSETTYPES.WATERMARKED_SOURCE_FILE,
        ];

        let multipleFilesCount = nft.nftAssets.reduce((acc, curr) => {
          if (mutipleSourceFiles.includes(curr.assetTypeId)) {
            return (acc = acc + 1);
          }
          return acc;
        }, 0);
        return {
          nftId: nft.nftId,
          thumbnailImage:
            nft.nftAssets?.find(a => a.assetTypeId === ASSETTYPES.THUMBNAIL)
              ?.url ?? '',
          thumbnailVideo:
            nft.nftAssets?.find(
              a => a.assetTypeId === ASSETTYPES.THUMBNAIL_VIDEO
            )?.url ?? '',
          trimmedAudio:
            nft.nftAssets?.find(a => a.assetTypeId === ASSETTYPES.TRIMMED_AUDIO)
              ?.url ?? '',
          frontCover:
            nft.nftAssets?.find(a => a.assetTypeId === ASSETTYPES.FRONT_COVER)
              ?.url ?? '',
          multipleSourceFiles: multipleFilesCount > 1,
          contractAddress: nft.contractAddress,
          nftContractId: nft.nftContractId,
          type: nft.category?.name,
          name: nft.name,
          description: nft.description,
          createdAt: nft.createdAt,
          userAvatar: nft?.user?.avatar || '',
          userAddress: nft?.user?.address || '',
          userName: nft?.user?.name || '',
          isLiked: nft.isLiked,
          isFavorite: nft.isFavorite,
          processing: nft.processing,
          likes: nft.likes,
          views: nft.views,
          price: nft.price,
          userId: nft.userId,
          user: nft.user,
          nftStatus: nft.nftStatus,
          nftStatusId: nft.nftStatusId,
          icon: nft?.payToken?.icon || '',
          paymentMethod: nft.paymentMethod,
          // TODO: add USD price?
        };
      });
      total.current = data.total;

      // set parent count
      if (setCount) setCount(data.total);

      if (refresh) {
        setNFTs(formattedData);
      } else {
        setNFTs(previousNFT => [...previousNFT, ...formattedData]);
      }
      start.current += itemsPerPage;

      if (start.current >= total.current) {
        setNoMoreData(true);
      }
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (setContainerLoading) {
      setContainerLoading(false);
    }
  };

  useEffect(() => {
    start.current = 0;
    fetchNftsHandler(true);
  }, [
    JSON.stringify(category),
    JSON.stringify(status),
    sortBy,
    searchKey,
    license,
    paytoken,
    community,
    paymentMethod,
    userId,
    onlyFavorites,
    onlyLikes,
    exceptNftId,
  ]);

  useEffect(() => {
    setTop(
      document.getElementById('search-box-main-page')?.getBoundingClientRect()
        ?.top || 0
    );
  }, []);

  useEffect(() => {
    if (showLoadMoreButton) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading) {
          fetchNftsHandler(false);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading, showLoadMoreButton]);

  const handleToggleFavorite = async (nftId, remove = false) => {
    const { data } = await toggleFavoriteItem(nftId, authToken);
    if (data?.message === 'OK') {
      if (onInteractionsUpdated) onInteractionsUpdated();

      if (remove) {
        setNFTs(prevNfts => prevNfts.filter(a => a.nftId !== nftId));
      } else {
        // toggle
        const nft = nfts.find(a => a.nftId === nftId);
        const idx = nfts.findIndex(a => a.nftId === nftId);
        setNFTs(prevNfts => {
          const newNfts = [...prevNfts];
          newNfts[idx] = { ...nft, isFavorite: !nft.isFavorite };
          return newNfts;
        });
      }
    }
  };

  const handleToggleLike = async (nftId, remove = false) => {
    const { data } = await toggleLikeItem(nftId, authToken);

    if (data?.message === 'OK') {
      if (onInteractionsUpdated) onInteractionsUpdated();

      if (remove) {
        setNFTs(prevNfts => prevNfts.filter(a => a.nftId !== nftId));
      } else {
        // toggle
        const nft = nfts.find(a => a.nftId === nftId);
        const idx = nfts.findIndex(a => a.nftId === nftId);

        setNFTs(prevNfts => {
          const newNfts = [...prevNfts];
          const updatedLikes = nft.isLiked
            ? nft.likes - 1
            : (nft.likes || 0) + 1;
          newNfts[idx] = { ...nft, isLiked: !nft.isLiked, likes: updatedLikes };
          return newNfts;
        });
      }
    }
  };

  const handleToggleUserFollow = async userId => {
    if (toggleUserFollow) {
      // notify parent to toggle follow
      toggleUserFollow(userId);
    } else {
      followUser(userId, authToken);
    }

    // update the isFollow for all, locally
    setNFTs(prevState =>
      prevState.map(nft => {
        if (nft.user.userId === userId) {
          return {
            ...nft,
            user: {
              ...nft.user,
              isFollowing: !nft.user.isFollowing,
            },
          };
        }
        return nft;
      })
    );
  };

  if (fetched && nfts.length === 0) {
    if (showLoadMoreButton) {
      return <></>;
    }
    return nothingToDisplay();
  }
  return (
    <>
      {showLoadMoreButton ? (
        <Box className={styles.moreTitle}>More from {userName}</Box>
      ) : (
        <></>
      )}

      <GradientCircularProgress
        className={cx(styles.loading, loading && styles.active)}
        size="1.2rem"
      />

      <ResponsiveMasonry
        className={cx(
          styles.responsiveContainer,
          !isLandingPage && styles.noPadding
        )}
        columnsCountBreakPoints={{
          120: 2,
          320: 2,
          768: 3,
          1200: !isLandingPage && !isOtherProfile ? 3 : 5,
          1500: !isLandingPage && !isOtherProfile ? 4 : 5,
          2000: !isLandingPage && !isOtherProfile ? 5 : 5,
        }}
      >
        <Masonry>
          {nfts.map((item, idx) => (
            <NFTItem
              ref={idx === nfts.length - itemsPerPage / 2 ? observerRef : null}
              key={item.nftId}
              isLoggedIn={Object.keys(me).length > 0}
              nft={item}
              user={me}
              account={account}
              handleToggleFavorite={remove =>
                handleToggleFavorite(item.nftId, remove)
              }
              handleToggleLike={remove => handleToggleLike(item.nftId, remove)}
              showStatus={showNftStatus}
              hideSubItems={
                hideSubItems || Boolean(onlyLikes) || Boolean(onlyFavorites)
              }
              likeOnTop={Boolean(onlyLikes)}
              favoriteOnTop={Boolean(onlyFavorites)}
              hideHoverButtons={
                Boolean(showNftStatus) || item.userId === me?.userId // cannot follow self
              }
              toggleUserFollow={handleToggleUserFollow}
              hideAvatarAndName={!isLandingPage && !onlyFavorites && !onlyLikes}
              openInNewTab={openInNewTab}
            />
          ))}
        </Masonry>

        {showLoadMoreButton && !noMoreData ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StyledButton
              onClick={async () => await fetchNftsHandler(false)}
              disabled={loading}
              secondary="true"
              sx={{
                borderRadius: '20px',
                minHeight: '40px',
                minWidth: '100px',
              }}
            >
              Show more
            </StyledButton>
          </Box>
        ) : (
          <></>
        )}
      </ResponsiveMasonry>

      {loading && !firstLoad ? (
        <div className={styles.loadingBottom}>
          <GradientCircularProgress
            className={cx(styles.loading, styles.active)}
            size="1.2rem"
          />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default MasonaryNFTs;
