import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { isAudioFormat } from 'utils';

import {
  Grid,
  Backdrop,
  Skeleton,
  styled,
  useMediaQuery,
  Box,
} from '@mui/material';

import { StyledButton } from 'components/StyledComponents';
import Header from 'components/header';
import styles from '../styles.module.scss';
import {
  AppRegistrationOutlined,
  MovingOutlined,
  AttachMoneyOutlined,
  AttachmentOutlined,
} from '@mui/icons-material';
import defaultAvatar from 'assets/imgs/default_avatar.png';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';
import Identicon from 'components/Identicon';
import { useResizeDetector } from 'react-resize-detector';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Line,
} from 'recharts';
import { useNFTContract } from 'contracts';

import Carousel from 'components/Carousel';
import SellModal from 'components/SellModal';
import DownloadModal from 'components/DownloadModal';

import { getContractTypeById } from 'constants/contracts.constants';
import { useAppKitAccount } from '@reown/appkit/react';
import { Description, NftDetailsButtons, PropertyItem } from '../components';
import 'react-alice-carousel/lib/alice-carousel.css';
import { NFT_STATUSES_CODES } from 'constants/NFTStatuses.constants';
import { formatNumber, formatError, formatDuration } from 'utils';
import showToast from 'utils/toast';
import Comments from './components/Comments';
import ActionMenu from './components/ActionMenu';

import { ethers } from 'ethers';

import { totalCommentsCount, totalLikes } from './utils';
import ShareModal from 'components/ShareModal';
import IconLoading from 'assets/icons/iconLoading';
import CarouselModal from 'components/CarouselModal';
import { contractTypeIds } from 'constants/contracts.constants';
import { ASSET_TYPES } from 'constants/asset.constants';
import MasonaryNFTs from 'components/MasonaryNFTs';
import KYCModal from 'components/KYCModal';
import FooterAction from 'components/FooterAction';
import MetaTags from 'components/MetaTags';
import { useDirectTransferContract } from 'contracts/directTransfer';
import StripePaymentModal from 'components/StripePaymentModal';

const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'rgba(35, 35, 52, 0.9)',
  zIndex: 9999,
}));

const TradeHistory = ({ buyer, seller, price, token, date }) => {
  const convertedDate = Math.floor(new Date(date) / 1000);
  return (
    <div className={styles.tradeContainer}>
      <div className={styles.tradeContent}>
        <div className={styles.name}>
          <div className={styles.nameTitle}>From</div>
          <div className={styles.nameContent}>
            <div className={styles.avatar}>
              {seller.avatar ? (
                <img src={`${seller.avatar}`} className={styles.avatarImg} />
              ) : (
                <Identicon
                  account={seller.name}
                  size={20}
                  className={styles.avatarImg}
                />
              )}
            </div>
            {seller.name}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.nameTitle}>To</div>
          <div className={styles.nameContent}>
            <div className={styles.avatar}>
              {buyer.avatar ? (
                <img src={`${buyer.avatar}`} className={styles.avatarImg} />
              ) : (
                <Identicon
                  account={buyer.name}
                  size={20}
                  className={styles.avatarImg}
                />
              )}
            </div>
            {buyer.name}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.nameTitle}>Price</div>
          <div className={styles.nameContent}>
            {token?.icon && (
              <img src={`${token.icon}`} className={styles.avatarImg} />
            )}
            {`${price} ${!token?.icon ? token?.symbol : 'USD'}`}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.date}>
            ({`${formatDuration(convertedDate)} ago`})
          </div>
        </div>
      </div>
    </div>
  );
};

const index = () => {
  const { id: tokenName, action } = useParams();
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
  });
  const history = useHistory();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width: 900px)');

  const { authToken, user } = useSelector(state => state.Auth);
  const userWallet = user?.userWallets?.at(0);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusId, setStatusId] = useState();
  const [isMine, setIsMine] = useState(null);
  const [createdNft, setCreatedNft] = useState({});
  const [isLiked, setIsLiked] = useState(false);
  const [totalShare, setTotalShare] = useState(0);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const [kycModalVisible, setKycModalVisible] = useState(false);

  const [downloadFiles, setDownloadFiles] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [thumbnailVideoUrl, setThumbnailVideoUrl] = useState();
  const [posterUrl, setPosterUrl] = useState();
  const [promo, setPromo] = useState([]);
  const [listingConfirming, setListingConfirming] = useState(false);
  const [cancelingListing, setCancelingListing] = useState(false);
  const [buyingItem, setBuyingItem] = useState(false);
  const [showComments, setShowComments] = useState(true);
  const [firstCommentsLoad, setFirstCommentsLoad] = useState(true);
  const [sourceFileURL, setSourceFileURL] = useState();
  const [showSourceFile, setShowSourceFile] = useState(true);
  const [clientSecret, setClientSecret] = useState();

  const {
    fetchCreatedNftByName,
    getNftTradeHistories,
    getNftImageOrAsset,
    increaseViewCount,
    addNftFlag,
    getTotalShare,
    requestReprocessing,
    verifyValidTradeAmount,
    toggleLikeItem,
    listNft,
    fetchNftStatus,
    cancelListingNft,
    updateListingNft,
    createStripeBuyLink,
    cancelBuyAsset,
  } = useApi();

  const { sendPayment, getTransferContract } = useDirectTransferContract();
  const { getERC20Contract } = useNFTContract();
  const { address: account } = useAppKitAccount();

  const priceHistory = [...tradeHistory].reverse().map(history => {
    const saleDate = new Date(history.saleDate);
    return {
      date: `${saleDate.getFullYear()}/${saleDate.getMonth() +
        1}/${saleDate.getDate()}`,
      price: history.price,
      amt: 2100,
    };
  });

  const downloadAvailable = useMemo(() => {
    if (
      createdNft?.artistId !== user.userId &&
      createdNft?.contractTypeId === contractTypeIds.NONEXCLUSIVE
    ) {
      if (createdNft.nftStatusId === NFT_STATUSES_CODES.LISTING) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }, [isMine, createdNft?.contractTypeId]);

  const code = new URLSearchParams(location.search).get('code');
  const contractType = createdNft?.contractType
    ? getContractTypeById(createdNft?.contractType?.contractTypeId)
    : undefined;

  const handleNftFlagged = async violation => {
    if (!authToken) return;

    const model = {
      nftId: createdNft.nftId,
      violation,
    };

    await addNftFlag(model, authToken);
    showToast('success', 'Thank you for your feedback!');
  };

  const asyncNft = async () => {
    setLoading(true);
    const response = await fetchCreatedNftByName(
      authToken,
      tokenName.replaceAll('+', ' ')
    );
    if (response.data && Object.keys(response.data).length > 0) {
      const nft = response.data;
      if (nft.nftStatusId === NFT_STATUSES_CODES.PREMINT) {
        history.push(`/draft/${nft.nftId}`, { from: 'explore' });
      }
      if (nft.nftStatusId < NFT_STATUSES_CODES.MINTED) {
        history.push(`/pending/${nft.nftId}`, { from: 'explore' });
      }
      setStatusId(nft.nftStatusId);
      const tempAsset = [];
      const sourceFile = [];

      let watermarkedThumbnailURL = '';
      let selectedImage;
      for (let i = 0; i < nft.nftAssets.length; i++) {
        const asset = nft.nftAssets[i];

        if (asset.assetType.name === ASSET_TYPES.PHOTOS) {
          tempAsset.push(asset.url);
        } else if (asset.assetType.name === ASSET_TYPES.THUMBNAIL_VIDEO) {
          setThumbnailVideoUrl(asset.url);
        } else if (asset.assetType.name === ASSET_TYPES.THUMBNAIL_IMAGE) {
          selectedImage = asset.parentId;
          setThumbnailUrl(asset.url);
        } else if (
          asset.assetType.name === ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE
        ) {
          watermarkedThumbnailURL = asset.url;
        } else if (
          asset.assetType.name === ASSET_TYPES.WATERMARKED_SOURCE_FILE
        ) {
          sourceFile.push(asset);
        } else if (asset.assetType.name === 'watermarkedThumbnailVideo') {
          sourceFile.push(asset);
        } else if (asset.assetType.name === 'downsizedAudio') {
          sourceFile.push(asset);
        }
      }

      if (sourceFile.length > 0 && selectedImage) {
        const foundChosenImageIndex = sourceFile.findIndex(
          el => el.parentId === selectedImage
        );
        const chosenFile = sourceFile[foundChosenImageIndex];
        sourceFile.splice(foundChosenImageIndex, 1);
        watermarkedThumbnailURL = chosenFile.url;
      }
      const sourceFileURL = sourceFile.map(item => item.url);
      setSourceFileURL(sourceFileURL);

      setPosterUrl(watermarkedThumbnailURL);

      setPromo(tempAsset);

      setAssets(
        !watermarkedThumbnailURL ||
          (sourceFileURL.length === 1 && isAudioFormat(sourceFileURL[0]))
          ? [...sourceFileURL]
          : [watermarkedThumbnailURL, ...sourceFileURL]
      );

      setCreatedNft(nft);
      // init total comment counts
      totalCommentsCount.value = nft.comments || 0;
      totalLikes.value = nft.likes || 0;
      setIsLiked(Boolean(nft.isLiked));
      if (user.userId === nft.userId) {
        setIsMine(true);
      }
    } else {
      history.replace('/404');
    }
    setLoading(false);
  };

  const toggleShowSourceFile = async () => {
    setAssets(
      showSourceFile
        ? [...promo]
        : [...(posterUrl ? [posterUrl] : []), ...sourceFileURL]
    );
    setShowSourceFile(prev => !prev);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderFiles = files => {
    return files?.map(file => {
      return (
        <a
          href={file.url}
          key={file.asset_id}
          className={styles.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={pdfPreviewIcon}
            alt={file.name}
            className={styles.documentIcon}
          />
        </a>
      );
    });
  };

  const getNftStatus = statusId => {
    return new Promise((resolve, reject) => {
      const fetchStatus = async () => {
        try {
          const response = await fetchNftStatus(authToken, createdNft.nftId);
          if (response.errors) {
            console.log(response.errors);
            reject(response.errors);
          } else if (Object.keys(response.data).length > 0) {
            const data = response.data;
            const newStatusId = data.status.nftStatusId;
            if (newStatusId !== statusId) {
              await asyncNft();
            }
            resolve(newStatusId);
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      };
      fetchStatus();
    });
  };

  useEffect(() => {
    if (!loading && authToken) {
      getNftStatus(statusId);
      const checkNftStatus = setInterval(() => {
        try {
          getNftStatus(statusId);
        } catch (e) {
          console.log("Couldn't get Asset status: ", e);
        }
      }, 1000 * 3);
      return () => clearInterval(checkNftStatus);
    }
  }, [statusId, loading, authToken]);

  const handleListItem = async (token, _price, paymentMethod, authToken) => {
    const nftId = createdNft.nftId;
    if (listingConfirming || !nftId) return;
    setListingConfirming(true);
    if (paymentMethod === 'crypto' && !userWallet && !userWallet?.address) {
      showToast('error', 'Please connect your wallet');
      setSellModalVisible(false);
      return;
    }

    if (paymentMethod === 'fiat' && !user.isStripeKYCed) {
      showToast('error', 'Please create a seller account');
      setSellModalVisible(false);
      return;
    }

    try {
      const { data } = await verifyValidTradeAmount(
        authToken,
        token?.address,
        _price,
        nftId
      );
      if (!data) {
        setKycModalVisible(true);
        setSellModalVisible(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    const { data } = await listNft(
      authToken,
      createdNft.nftId,
      parseFloat(_price),
      paymentMethod,
      token?.address
    );

    if (data && data.message === 'OK') {
      await getNftStatus(statusId);
      setSellModalVisible(false);
      setCreatedNft(prev => ({
        ...prev,
        price: _price,
        payToken: token,
        paymentMethod: paymentMethod,
      }));
      showToast('success', 'Item listed successfully!');
    } else {
      showToast('error', 'There is an issue while listing, please try again');
    }
    setListingConfirming(false);
  };

  const handleUpdateListing = async (token, _price, paymentMethod) => {
    const nftId = createdNft.nftId;
    if (listingConfirming || !nftId) return;
    if (paymentMethod === 'crypto' && !account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    if (paymentMethod === 'fiat' && !user.isStripeKYCed) {
      showToast('error', 'Please create a seller account');
      return;
    }

    setListingConfirming(true);

    try {
      const { data } = await verifyValidTradeAmount(
        authToken,
        token?.address,
        _price,
        nftId
      );
      if (!data) {
        setKycModalVisible(true);
        setSellModalVisible(false);

        return;
      }
    } catch (error) {
      console.log(error);
    }

    const { data } = await updateListingNft(
      authToken,
      createdNft.nftId,
      parseFloat(_price),
      paymentMethod,
      token?.address
    );

    if (data && data.message === 'OK') {
      await getNftStatus(statusId);
      setSellModalVisible(false);
      setCreatedNft(prev => ({
        ...prev,
        price: _price,
        paymentMethod: paymentMethod,
        payToken: token,
      }));
      showToast('success', 'Item updated successfully!');
    } else {
      showToast('error', 'There is an issue while listing, please try again');
    }
    setListingConfirming(false);
  };

  const handleCancelListing = async () => {
    if (cancelingListing) return;
    setCancelingListing(true);
    try {
      const { data } = await cancelListingNft(authToken, createdNft.nftId);
      if (data && data.message === 'OK') {
        await getNftStatus(statusId);
        setCreatedNft(prev => ({
          ...prev,
          price: null,
          payToken: null,
        }));
        showToast('success', 'Item unlisted successfully!');
      } else {
        showToast(
          'error',
          'There is an issue while unlisting, please try again'
        );
      }
    } catch (e) {
      showToast(
        'error',
        'There is an issue while canceling listing, please try again'
      );
      console.log(e);
    }
    setCancelingListing(false);
  };

  const handleDownloadFile = async () => {
    if (!downloadAvailable) {
      showToast(
        'error',
        'You need to list / start auctioning the asset before downloading'
      );
      return;
    }
    const { data, errors: downloadErrs } = await getNftImageOrAsset(
      createdNft.nftId,
      authToken
    );
    if (downloadErrs) {
      if (downloadErrs[0].message?.includes('download limit')) {
        showToast(
          'error',
          formatError({
            data: {
              message:
                'You reached the maximum of downloads allowed for this file.',
            },
          })
        );
      }

      return;
    }

    setDownloadFiles(data.map(item => ({ ...item, progress: 0 })));

    setDownloadModalVisible(true);
  };

  const handleBuyItem = async () => {
    if (buyingItem) return;
    if (!account && createdNft.paymentMethod === 'crypto') {
      showToast('error', 'Please connect your wallet');
      return;
    }

    try {
      // Check pending status
      const statusId = await fetchNftStatus();
      if (
        [
          NFT_STATUSES_CODES.STRIPE_PROCESSING,
          NFT_STATUSES_CODES.TRANSAK_PROCESSING,
          NFT_STATUSES_CODES.PENDING_TRANSFERRING,
        ].includes(statusId)
      ) {
        showToast('warning', 'This asset is not available for sale');
        return;
      }

      const { data } = await verifyValidTradeAmount(
        authToken,
        createdNft.payToken.address,
        createdNft.price,
        createdNft.nftId
      );

      if (!data) {
        setKycModalVisible(true);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      setBuyingItem(true);

      const processCryptoBuy = async () => {
        const erc20 = await getERC20Contract(createdNft.payToken.address);
        const price = ethers.utils.parseUnits(
          createdNft.price.toString(),
          createdNft.payToken.decimals
        );

        const transferContract = await getTransferContract();
        const balance = await erc20.balanceOf(account);

        if (balance.lt(price)) {
          showToast(
            'error',
            `Do not have enough ${createdNft.payToken.symbol}`
          );
          setBuyingItem(false);
          return;
        }

        const allowance = await erc20.allowance(
          account,
          transferContract.address
        );

        if (allowance.lt(price)) {
          const tx = await erc20.approve(transferContract.address, price);
          if (tx) {
            await tx.wait();
          }
        }

        const tx = await sendPayment(
          createdNft.user.address,
          createdNft.payToken.address,
          price,
          createdNft.nftContractId
        );

        if (tx) {
          await tx.wait();
        }
        showToast('warning', 'We are processing your payment');
      };

      const processFiatBuy = async () => {
        // Create payment session
        const { data } = await createStripeBuyLink(authToken, createdNft.nftId);

        if (data) {
          setClientSecret(data);
        } else {
          setClientSecret();
        }
      };

      if (createdNft.paymentMethod === 'fiat') {
        await processFiatBuy();
      } else {
        await processCryptoBuy();
      }
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error, 'Buy Asset'));
    }
    setBuyingItem(false);
  };

  const getTradeHistory = async tokenID => {
    if (tokenID) {
      const response = await getNftTradeHistories(tokenID);
      setTradeHistory(response.data);
    }
  };

  const handleTotalShares = async tokenID => {
    if (tokenID) {
      try {
        const { data } = await getTotalShare(tokenID);
        setTotalShare(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (createdNft.nftId) {
      if (authToken) {
        increaseViewCount(authToken, createdNft.nftId, code);
      }
    }
  }, [authToken, createdNft.nftId]);

  useEffect(() => {
    if (
      !isMine &&
      action === 'buy' &&
      createdNft.nftStatusId === NFT_STATUSES_CODES.LISTING
    ) {
      userNavigate(() => handleBuyItem());
    }
  }, [action, isMine]);

  useEffect(() => {
    asyncNft();
  }, [authToken]);

  useEffect(() => {
    getTradeHistory(createdNft.nftId);
    handleTotalShares(createdNft.nftId);
  }, [createdNft.nftId]);

  const userNavigate = action => {
    if (Object.keys(user).length === 0) {
      history.push('/auth/sign-in');
    } else if (!user.is2fa) {
      history.push('/profile/2fa');
    } else if (createdNft.paymentMethod === 'crypto' && !user.address) {
      history.push('/profile/connect-wallet');
    } else if (user?.kycRequired) {
      history.push('/profile/kyc');
    } else if (user?.isAdmin) {
      showToast(
        'error',
        'This is an admin account. This action is not allowed.'
      );
    } else {
      action();
    }
  };

  const onShareClicked = () => {
    setShareModalVisible(true);
  };

  const onCommentsClicked = () => {
    setShowComments(prev => !prev);
  };

  const onLikesClicked = async () => {
    const { data } = await toggleLikeItem(createdNft.nftId, authToken);

    if (data?.message === 'OK') {
      setIsLiked(prev => {
        if (prev) {
          totalLikes.value = totalLikes.value - 1;
        } else {
          totalLikes.value = totalLikes.value + 1;
        }
        return !prev;
      });
    }
  };

  const handleRequestReprocessing = async () => {
    try {
      await requestReprocessing(authToken, createdNft.nftId);
      setCreatedNft(prev => ({ ...prev, processing: 'pending' }));
    } catch (error) {
      console.log(error);
    }
  };

  const renderAction = () => {
    return (
      <>
        <div className={styles.actionGroup}>
          {
            <>
              {!isMine && statusId === NFT_STATUSES_CODES.LISTING && (
                <StyledButton
                  onClick={async () => userNavigate(() => handleBuyItem())}
                  width={isMobile ? '100%' : 'auto'}
                  disabled={buyingItem}
                  sx={{ minHeight: '40px' }}
                >
                  {buyingItem ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Buy Now'
                  )}
                </StyledButton>
              )}
              {isMine ? (
                statusId !== NFT_STATUSES_CODES.STRIPE_PROCESSING ? (
                  <>
                    <StyledButton
                      onClick={async () => {
                        setSellModalVisible(true);
                      }}
                      width={isMobile ? '100%' : 'auto'}
                      disabled={listingConfirming || cancelingListing}
                      sx={{ minHeight: '40px' }}
                    >
                      {listingConfirming ? (
                        <ClipLoader color="#FFF" size={16} />
                      ) : statusId === NFT_STATUSES_CODES.LISTING ? (
                        'Update Listing'
                      ) : (
                        'List Your Asset'
                      )}
                    </StyledButton>

                    {statusId === NFT_STATUSES_CODES.LISTING && (
                      <StyledButton
                        onClick={async () => handleCancelListing()}
                        width={isMobile ? '100%' : 'auto'}
                        disabled={cancelingListing || listingConfirming}
                        sx={{ minHeight: '40px' }}
                      >
                        {cancelingListing ? (
                          <ClipLoader color="#FFF" size={16} />
                        ) : (
                          'Cancel Listing'
                        )}
                      </StyledButton>
                    )}

                    <StyledButton
                      onClick={async () => handleDownloadFile()}
                      width={isMobile ? '100%' : 'auto'}
                      sx={{ minHeight: '40px' }}
                    >
                      Download
                    </StyledButton>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box fontSize="13px" color="var(--primary-color)">
                      Someone is buying your asset
                    </Box>
                    <IconLoading />
                  </Box>
                )
              ) : (
                <></>
              )}
            </>
          }
        </div>

        <NftDetailsButtons
          showComments={showComments}
          onShareClicked={onShareClicked}
          onCommentsClicked={onCommentsClicked}
          onLikesClicked={onLikesClicked}
          totalShare={totalShare}
          totalLike={createdNft.likes}
          isLiked={isLiked}
        />
      </>
    );
  };

  const handleStripeModalClose = async () => {
    setClientSecret();
    await cancelBuyAsset(authToken, createdNft.nftId);
  };

  return (
    <div className={cx(styles.container)}>
      {Object.keys(createdNft).length > 0 && (
        <MetaTags
          title={createdNft.name}
          description={createdNft.description}
          image={thumbnailUrl ? thumbnailUrl : ''}
          video={thumbnailVideoUrl ? thumbnailVideoUrl : ''}
          name={createdNft.user?.name}
        />
      )}
      <Header isSticky="sticky" />
      <StyledBackdrop open={loading} sx={{ minHeight: '40px' }}>
        <Loader
          type="Oval"
          color="var(--primary-color)"
          height={50}
          width={50}
        />
      </StyledBackdrop>
      {Object.keys(createdNft).length > 0 && (
        <Grid container className={styles.body}>
          <Grid item xs={12} md={8} className={styles.thumbnailContainer}>
            <div className={cx(styles.ad, styles.hidden)}>
              {/* remove 'hidden' to show ad */}
              Advertisement
            </div>
            {assets.length > 0 && (
              <Carousel
                items={assets}
                thumbnailUrl={thumbnailUrl}
                posterUrl={posterUrl}
                isMobile={isMobile}
                nftName={createdNft.name}
                ownerName={createdNft.user.name}
                setCarouselIndex={setCarouselIndex}
              />
            )}
            {!isMobile && showComments && (
              <Comments
                user={user}
                nft={createdNft}
                setFirstCommentsLoad={setFirstCommentsLoad}
                firstCommentsLoad={firstCommentsLoad}
              />
            )}

            {!isMobile && createdNft?.userId !== user?.userId && (
              <MasonaryNFTs
                sortBy="createdAt"
                userId={createdNft?.user.userId}
                userName={createdNft?.user.name}
                itemsPerPage={10}
                showLoadMoreButton
                exceptNftId={createdNft.nftId} // Avoid showing this NFT
                openInNewTab
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            ref={ref}
            className={cx(styles.details, styles.fullSize)}
          >
            <div className={styles.detailsContent}>
              <div className={styles.title}>
                <span>{createdNft?.name}</span>
                {!isMine && authToken && (
                  <span className={styles.actions}>
                    <ActionMenu
                      isMobile={isMobile}
                      onNftFlagged={handleNftFlagged}
                    />
                  </span>
                )}
              </div>
              <div className={styles.itemStats}>
                {loading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <>
                    {createdNft?.user?.avatar ? (
                      <img
                        src={`${
                          createdNft?.user?.avatar.includes('mypinata')
                            ? `${createdNft?.user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                            : createdNft?.user?.avatar
                        }`}
                        className={styles.ownerAvatar}
                      />
                    ) : (
                      <div className={styles.ownerAvatar}>
                        <img
                          src={defaultAvatar}
                          alt={'avatar'}
                          className={styles.avatar}
                        />
                      </div>
                    )}
                    <>
                      Owned by&nbsp;
                      <Link
                        to={
                          isMine
                            ? `/profile`
                            : `/account/${createdNft.user?.name.replaceAll(
                                ' ',
                                '+'
                              )}`
                        }
                        className={styles.ownerName}
                      >
                        {isMine ? 'Me' : createdNft?.user?.name}
                      </Link>
                    </>
                  </>
                )}
              </div>
              {isMine && createdNft.processing === 'pending' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box fontSize="13px" color="var(--primary-color)">
                    Your Asset is processing
                  </Box>
                  <IconLoading />
                </Box>
              )}
              {isMine && createdNft.processing === 'failed' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box fontSize="13px" color="var(--primary-color)">
                    Your Asset failed to process
                  </Box>
                  <StyledButton
                    className={styles.textButton}
                    secondary="true"
                    onClick={handleRequestReprocessing}
                    sx={{
                      p: 0,
                      ml: 1,
                    }}
                  >
                    Retry
                  </StyledButton>
                </Box>
              )}
              {
                <>
                  {statusId === NFT_STATUSES_CODES.LISTING && (
                    <div className={styles.currentPriceContainer}>
                      <div className={styles.currentPriceTitle}>
                        Current price
                      </div>
                      <div className={styles.currentPriceValue}>
                        {createdNft.paymentMethod === 'crypto' &&
                          createdNft.payToken && (
                            <img
                              src={createdNft.payToken?.icon}
                              alt="token icon"
                            />
                          )}
                        <div className={styles.currentPrice}>
                          {`$${formatNumber(createdNft.price)} ${
                            createdNft.paymentMethod === 'fiat' ? 'USD' : ''
                          }`}
                        </div>
                      </div>
                    </div>
                  )}
                  {!createdNft.processing && renderAction()}
                </>
              }
            </div>
            <div className={styles.description}>
              <Description description={createdNft?.description} />
            </div>
            <div className={styles.scrollContainer}>
              <div
                className={cx(
                  styles.scrollContent,
                  showComments && styles.fullSize
                )}
              >
                <PropertyItem
                  title={
                    <>
                      <AppRegistrationOutlined />
                      Registry
                    </>
                  }
                >
                  <Grid container className={styles.itemsList}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.documentContainer}
                    >
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Category</div>
                        <div className={styles.registryValue}>
                          {createdNft?.category.type}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Subcategory</div>
                        <div
                          className={cx(styles.registryValue, styles.tagValue)}
                        >
                          {createdNft?.subcategories?.map((item, index) => (
                            <div className={styles.tagItem} key={index}>
                              {item.type}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Tags</div>
                        <div
                          className={cx(styles.registryValue, styles.tagValue)}
                        >
                          {createdNft?.tags &&
                            createdNft?.tags.map(item => (
                              <Link
                                to={`/search/${item.name}`}
                                className={styles.tagItem}
                                key={item.tagId}
                              >
                                {item.name}
                              </Link>
                            ))}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Asset Owner is Licensed Under
                        </div>
                        <div className={styles.registryValue}>
                          {createdNft?.contractType
                            ? createdNft.contractType.name
                            : ''}
                        </div>
                      </div>
                      {
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Artist Name
                          </div>
                          <div className={styles.registryValue}>
                            {createdNft?.artist
                              ? createdNft?.artist?.name
                              : '---'}
                          </div>
                        </div>
                      }
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Royalty</div>
                        <div className={styles.registryValue}>
                          {createdNft?.royalty}%
                        </div>
                      </div>
                      {createdNft?.artist?.website &&
                        createdNft?.artist?.website !== 'undefined' && (
                          <div className={styles.documentPaddings}>
                            <div className={styles.registryLabel}>
                              Artist Website
                            </div>
                            <div className={styles.registryValue}>
                              {createdNft?.artist?.website ? (
                                <a
                                  href={`https://${createdNft?.artist_website}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.link}
                                >
                                  {createdNft?.artist?.website}
                                </a>
                              ) : (
                                '---'
                              )}
                            </div>
                          </div>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.documentContainer}
                    >
                      {contractType && (
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            {contractType.label}
                          </div>
                          <div className={styles.registryValue}>
                            {renderFiles([
                              {
                                url: contractType.url,
                                asset_id: contractType.id,
                              },
                            ])}
                          </div>
                        </div>
                      )}
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Model/Actor Releases
                        </div>
                        <div>
                          {createdNft?.actorModelReleases &&
                          createdNft?.actorModelReleases.length
                            ? renderFiles(createdNft?.actorModelReleases)
                            : 'No files attached.'}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Additional Documents
                        </div>
                        <div>
                          {createdNft?.additionalDocuments &&
                          createdNft?.additionalDocuments.length
                            ? renderFiles(createdNft?.additionalDocuments)
                            : 'No files attached.'}
                        </div>
                      </div>
                      {promo.length > 0 && sourceFileURL && (
                        <StyledButton onClick={() => toggleShowSourceFile()}>
                          {!showSourceFile
                            ? `See Asset${sourceFileURL.length > 0 ? 's' : ''}`
                            : 'See Promo Content'}
                        </StyledButton>
                      )}
                    </Grid>
                  </Grid>
                </PropertyItem>
                {createdNft?.attributes && (
                  <PropertyItem
                    title={
                      <>
                        <AttachmentOutlined />
                        Attributes
                      </>
                    }
                  >
                    <div className={styles.panelBody}>
                      {createdNft.attributes.map(item => (
                        <div className={styles.panelLine} key={item.attribute}>
                          <div className={styles.panelLabel}>
                            {item.attribute}
                          </div>
                          <div className={styles.panelValue}>{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </PropertyItem>
                )}
                {/* <PropertyItem
                  title={
                    <>
                      <SellOutlined />
                      Direct Offers
                    </>
                  }
                  expanded={offers.length > 0}
                >
                  {offers.map(item => (
                    <Offer
                      key={item.offerId}
                      authToken={authToken}
                      user={item.creator}
                      price={item.price}
                      deadline={item.deadline}
                      token={createdNft?.payToken}
                      isMine={isMine}
                      salesContractApproved={salesContractApproved}
                      offerAccepting={offerAccepting}
                      salesContractApproving={salesContractApproving}
                      handleAcceptOffer={handleAcceptOffer}
                      handleApproveSalesContract={handleApproveSalesContract}
                    />
                  ))}
                </PropertyItem> */}
                <PropertyItem
                  title={
                    <>
                      <AttachMoneyOutlined />
                      Price History
                    </>
                  }
                >
                  {width > 0 ? (
                    <div className={styles.chartWrapper}>
                      <div className={styles.chart}>
                        <LineChart
                          width={width - 100}
                          height={250}
                          data={priceHistory}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="date" />
                          <YAxis />
                          <ChartTooltip />
                          <CartesianGrid stroke="#eee" />
                          <Line
                            type="monotone"
                            dataKey="price"
                            stroke="#2479FA"
                          />
                        </LineChart>
                      </div>
                    </div>
                  ) : (
                    <div>{width}</div>
                  )}
                </PropertyItem>
                <PropertyItem
                  title={
                    <>
                      <MovingOutlined />
                      Trade History
                    </>
                  }
                >
                  {tradeHistory.map(item => (
                    <TradeHistory
                      buyer={item.buyer}
                      seller={item.seller}
                      price={item.price}
                      date={item.saleDate}
                      token={createdNft.payToken}
                      key={item.tradeHistoryId}
                    />
                  ))}
                </PropertyItem>
              </div>
            </div>

            {isMobile && showComments && (
              <div className={styles.commentsMobile}>
                <Comments
                  user={user}
                  nft={createdNft}
                  isMobile
                  setFirstCommentsLoad={setFirstCommentsLoad}
                  firstCommentsLoad={firstCommentsLoad}
                />
              </div>
            )}
            {isMobile && createdNft?.userId !== user?.userId && (
              <MasonaryNFTs
                sortBy="createdAt"
                userId={createdNft?.user.userId}
                userName={createdNft?.user.name}
                itemsPerPage={10}
                showLoadMoreButton
                exceptNftId={createdNft.nftId} // Avoid showing this NFT
                openInNewTab
              />
            )}
            <div className={styles.ad}>Advertisement</div>
          </Grid>
        </Grid>
      )}

      <SellModal
        authToken={authToken}
        visible={sellModalVisible}
        onClose={() => setSellModalVisible(false)}
        onSell={
          statusId === NFT_STATUSES_CODES.LISTING
            ? handleUpdateListing
            : handleListItem
        }
        startPrice={createdNft?.price || 0}
        payToken={createdNft && createdNft.payToken}
        confirming={listingConfirming}
        paymentMethod={createdNft.paymentMethod}
        isStripeKYCed={user.isStripeKYCed}
        isCreator={user.userId === createdNft.artistId}
        walletAddress={user.address}
      />
      <DownloadModal
        visible={downloadModalVisible}
        onClose={() => {
          setDownloadModalVisible(false);
          setDownloadFiles([]);
        }}
        downloadFiles={downloadFiles}
        nftName={createdNft?.name}
      />

      {Object.keys(createdNft).length > 0 && (
        <ShareModal
          visible={shareModalVisible}
          onClose={() => setShareModalVisible(false)}
          nft={createdNft}
        />
      )}
      <CarouselModal items={assets} startIndex={carouselIndex} />

      <KYCModal
        visible={kycModalVisible}
        onClose={() => setKycModalVisible(false)}
      />
      <FooterAction hideFooter={authToken} />
      <StripePaymentModal
        visible={Boolean(clientSecret)}
        onClose={handleStripeModalClose}
        clientSecret={clientSecret}
      />
    </div>
  );
};

export default index;
