import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';
import Footer from '../../../components/Footer';

const TermsOfUse = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="February 07, 2025"
          title="Terms Of Service"
          description=""
          url="/files/terms of service.pdf"
        >
          <div className={styles.authForm}>
            <h2>Welcome to Minti</h2>
            <p>
              We’re glad you’re here! Please take a moment to review our Terms
              of Use (&quot;Terms&quot;). By accessing or using our services,
              you agree to abide by these Terms.
            </p>
            <h2>1. Acceptance of These Terms</h2>
            <p>
              These Terms constitute a legally binding contract between you and
              <span className={styles.bold}>Fullstock Media Inc</span>{' '}
              (&quot;Minti, us, we, our&quot;). If you are a buyer, Fullstock
              Media Inc. serves as the merchant of record.
            </p>
            <p>
              This contract outlines your rights and responsibilities when using
              <span className={styles.bold}>minti.fun</span>, our mobile
              applications, and other services provided by Minti (collectively
              referred to as &quot;Services&quot;). By using our
              Services—including simply browsing—you agree to these Terms. If
              you do not agree, you may not use our Services.
            </p>
            <h2>2. Related Policies and Documents</h2>
            <p>
              Minti connects people globally to create, sell, and purchase
              unique goods. Depending on how you use our Services, additional
              policies may apply:
            </p>
            <ul>
              <li>
                <span className={styles.bold}>General Policies</span>: Applies
                to all users, including our Privacy Policy and
                Anti-Discrimination Policy.
              </li>
              <li>
                <span className={styles.bold}>Seller Policies</span>: Applies if
                you list items for sale.
              </li>
              <li>
                <span className={styles.bold}>Buyer Policies</span> : Covers
                rules for browsing and shopping.
              </li>
              <li>
                <span className={styles.bold}>Third-Party Policies</span>:
                Applies to intellectual property owners, API users, affiliates,
                and information requestors.
              </li>
              <li>
                <span className={styles.bold}>
                  Search, Advertising, and Recommendation Disclosures
                </span>
                : Explains how search and advertising results are ranked.
              </li>
            </ul>
            <p>
              These policies are part of our Terms, so please review them
              carefully.
            </p>
            <h2>3. Privacy and Data Handling</h2>
            <p>
              Your privacy is important to us. Our Privacy Policy explains how
              we collect, use, and share your information. By using our
              Services, you agree to this data processing.
            </p>
            <ul>
              <li>
                <span className={styles.bold}>Data Control</span>: Both Minti
                and sellers act as independent data controllers under EU law.
              </li>
              <li>
                <span className={styles.bold}>Liability for Data Handling</span>
                : If you (as a seller) are found to be a joint data controller
                with Minti and legal issues arise due to your actions, you agree
                to indemnify Minti per Section 9.
              </li>
            </ul>
            <h2>4. Your Minti Account</h2>
            <p>To access certain Services, you must create an account.</p>
            <ul>
              <li>
                <span className={styles.bold}>Eligibility</span>: You must be
                18+ to use our Services. Minors (ages 13–17) may use an account
                under direct parental supervision. Children under 13 cannot use
                Minti.
              </li>
              <li>
                <span className={styles.bold}>Account Accuracy</span>: You must
                provide truthful and accurate information.
              </li>
              <li>
                <span className={styles.bold}>Account Responsibility</span>: You
                are responsible for all activity under your account, including
                transactions by minors.
              </li>
              <li>
                <span className={styles.bold}>Security</span>: Keep your
                password secure. We are not liable for unauthorized account
                access.
              </li>
              <li>
                <span className={styles.bold}>Legal Relationship</span>: These
                Terms do not create an employment, partnership, or franchise
                relationship between you and Minti.
              </li>
            </ul>
            <h2>5. Your Content</h2>
            <p>
              By posting content on Minti (e.g., shop names, listings, photos,
              videos, reviews), you retain ownership but grant us a license to
              use it:
            </p>
            <ul>
              <li>
                <span className={styles.bold}>License Grant</span>: You provide
                Minti with a non-exclusive, worldwide, royalty-free,
                sub-licensable, and perpetual license to use, display, modify,
                and distribute your content for platform functionality and
                marketing.
              </li>
              <li>
                <span className={styles.bold}>
                  Intellectual Property Compliance
                </span>
                : If content is posted without authorization, report it via our
                Intellectual Property Policy.
              </li>
              <li>
                <span className={styles.bold}>Content Restrictions</span>: You
                agree not to post false, misleading, illegal, or offensive
                content.
              </li>
            </ul>
            <h2>6. Using Our Services</h2>
            <ul>
              <li>
                <span className={styles.bold}>Compliance with Laws</span>: You
                must follow all applicable laws, including local business and
                taxation regulations.
              </li>
              <li>
                <span className={styles.bold}>Payment Obligations</span>: You
                are responsible for all fees owed to Minti.
              </li>
              <li>
                <span className={styles.bold}>Prohibited Conduct</span>:
                <ul>
                  <li>Do not scrape, hack, or disrupt Minti’s systems.</li>
                  <li>
                    Do not infringe on Minti trademarks. (see Trademark Policy)
                  </li>
                  <li>Do not use the Services for illegal activities.</li>
                </ul>
              </li>
              <li>
                <span className={styles.bold}>Content Restrictions</span>: You
                agree not to post false, misleading, illegal, or offensive
                content.
              </li>
            </ul>
            <p>Download to read more...</p>
          </div>
        </LicenseGrid>
      </div>
      <Footer />

      <FooterAction />
    </div>
  );
};
export default TermsOfUse;
