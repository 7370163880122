import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItem, Stack } from '@mui/material';

export default function AutocompleteStripeCountry({
  countries,
  onSelect,
  value: selectedCountry,
  displayText = 'Select Your Country',
  displayField = 'name',
}) {
  const [value, setValue] = React.useState(selectedCountry);

  const handleChange = (_, value) => {
    setValue(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  const getDisplayValue = (option, field) => {
    if (!option) {
      return '';
    }

    return option[field] || '';
  };

  return (
    <Autocomplete
      disablePortal
      value={value}
      options={countries}
      getOptionLabel={option => getDisplayValue(option, displayField)}
      freeSolo
      renderOption={(props, option) => (
        <ListItem
          {...props}
          key={option.id}
          sx={{
            '&.Mui-focused': {
              backgroundColor: 'var(--secondary-background-color) !important',
              '&:hover': {
                backgroundColor: 'var(--background-color) !important',
              },
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'var(--secondary-background-color) !important',
              '&:hover': {
                backgroundColor: 'var(--background-color) !important',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
              alt=""
            />
            <span>{getDisplayValue(option, displayField)}</span>
          </Stack>
        </ListItem>
      )}
      isOptionEqualToValue={(option, val) => option?.id === val?.id}
      onChange={handleChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            placeholder={(!value?.id && displayText) || ''}
            variant="outlined"
            sx={{
              '.Mui-focused': {
                color: 'var(--primary-color) !important',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--primary-color) !important',
                },
              },
              '.MuiFormLabel-root': {
                color: 'var(--primary-text-color)',
              },
              '.MuiChip-root': {
                backgroundColor: 'var(--primary-color)',
                color: 'var(--white-color)',
              },
              '.MuiAutocomplete-inputRoot': {
                width: '100%',
                minHeight: '50px',
                boxSizing: 'border-box',
                fontSize: '16px',
                color: 'var(--primary-text-color)',
                borderRadius: '10px !important',
                outline: 'none',
                backgroundColor: 'var(--place-holder-color)',
                border: '1px var(--light-border-color) solid',
              },
              'MuiAutocomplete-popupIndicator': {
                svg: {
                  color: 'var(--primary-text-color)',
                },
              },
            }}
          />
        );
      }}
      sx={{
        width: '100%',
        '.MuiAutocomplete-endAdornment': {
          svg: {
            color: 'var(--primary-text-color)',
          },
        },
      }}
    />
  );
}
