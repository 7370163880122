import FilterConstants from '../constants/filter.constants';

const initialState = {
  search: '',
  status: [],
  paytoken: [],
  paymentMethod: [],
  community: [],
  collections: [],
  category: [],
  license: null,
  groupType: 'all',
  sortBy: 'views',
};

export function Filter(state = initialState, action) {
  switch (action.type) {
    case FilterConstants.UPDATE_SEARCH_PHRASE: {
      return { ...state, search: action.search };
    }
    case FilterConstants.UPDATE_STATUS_FILTER: {
      let tempArray = [];

      if (action.field) {
        tempArray = [...state.status];
        if (tempArray.includes(action.field)) {
          tempArray.splice(tempArray.indexOf(action.field), 1);
        } else {
          tempArray.push(action.field);
        }
      }

      return { ...state, status: tempArray };
    }
    case FilterConstants.RESET_STATUS_FILTER: {
      const newState = { ...state, status: [] };
      return newState;
    }
    case FilterConstants.UPDATE_PAYTOKEN_FILTER: {
      if (!action.field) {
        return {
          ...state,
          paytoken: [],
        };
      }

      let tempArray = [...state.paytoken];

      if (Array.isArray(action.field)) {
        tempArray = action.field;
      } else {
        tempArray = tempArray.filter(
          item => item.payTokenId !== action.field.payTokenId
        );
      }

      return {
        ...state,
        paytoken: tempArray,
      };
    }
    case FilterConstants.RESET_PAYTOKEN_FILTER: {
      const newState = { ...state, paytoken: [] };
      return newState;
    }
    case FilterConstants.UPDATE_COMMUNITY_FILTER: {
      if (!action.field) {
        return {
          ...state,
          community: [],
        };
      }

      let tempArray = [...state.community];

      if (Array.isArray(action.field)) {
        tempArray = action.field;
      } else {
        tempArray = tempArray.filter(
          item => item.payTokenId !== action.field.payTokenId
        );
      }

      return {
        ...state,
        community: tempArray,
      };
    }
    case FilterConstants.RESET_COMMUNITY_FILTER: {
      const newState = { ...state, community: [] };
      return newState;
    }
    case FilterConstants.UPDATE_COLLECTIONS_FILTER: {
      return {
        ...state,
        collections: action.collections,
      };
    }
    case FilterConstants.UPDATE_CATEGORIES_FILTER: {
      let tempArray = [];
      if (action.category) {
        tempArray = [...state.category];
        const labels = tempArray.map(item => item.type);

        if (labels.includes(action.category.type)) {
          tempArray.splice(labels.indexOf(action.category.type), 1);
        } else {
          tempArray.push(action.category);
        }
      }
      return {
        ...state,
        category: tempArray,
      };
    }
    case FilterConstants.RESET_CATEGORIES_FILTER: {
      const newState = { ...state, category: [] };
      return newState;
    }
    case FilterConstants.UPDATE_GROUP_TYPE_FILTER: {
      return {
        ...state,
        groupType: action.groupType,
      };
    }
    case FilterConstants.UPDATE_SORT_BY_FILTER: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case FilterConstants.UPDATE_LICENSE_FILTER: {
      return {
        ...state,
        license: action.license,
      };
    }
    case FilterConstants.UPDATE_PAYMENT_METHOD_FILTER: {
      return {
        ...state,
        paymentMethod: action.field,
      };
    }
    default: {
      return state;
    }
  }
}
