import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';
import Footer from '../../../components/Footer';

import { Grid } from '@mui/material';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';
import { contractTypes } from '../../../constants/contracts.constants';
const Licenses = () => {
  const renderFiles = files => {
    return files?.map(file => {
      return (
        <a
          href={file.url}
          key={file.url}
          className={styles.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={pdfPreviewIcon}
            alt={file.name}
            className={styles.documentIcon}
          />
        </a>
      );
    });
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="February 11, 2025"
          title="Minti Asset Licenses"
          url=""
          description="When a user purchases a Minti Asset, one of the following licenses will apply to the Asset and associated source file. Check the Asset registry on the Minti Asset platform to see the accompanying license to each Minti Asset."
        >
          <div className={styles.authForm}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {contractTypes.map(item => (
                <Grid
                  item
                  key={item.id}
                  xs={11}
                  md={6}
                  className={styles.licenseGridItem}
                >
                  <div className={styles.registryLabel}>{item.label}</div>
                  <div className={styles.registryValue}>
                    {renderFiles([{ url: item.url }])}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </LicenseGrid>
      </div>
      <Footer />
      <FooterAction />
    </div>
  );
};
export default Licenses;
