import React from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterActions from 'actions/filter.actions';

import { PaymentMethodStatus } from 'constants/filter.constants';
import { Paid } from '@mui/icons-material';

const PaymentFilter = () => {
  const dispatch = useDispatch();

  const { paymentMethod } = useSelector(state => state.Filter);

  const handleStatusChange = field => {
    if (paymentMethod === field) {
      dispatch(FilterActions.updatePaymentMethodFilter([]));
    } else {
      dispatch(FilterActions.updatePaymentMethodFilter(field));
    }
  };

  return (
    <Box
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'var(--place-holder-color)',
          color: 'var(--primary-text-color)',
          '& svg': {
            fill: 'var(--primary-text-color)',
          },
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',

          '& svg': {
            scale: '0.7',
          },
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 15,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          boxShadow: 'none',
          m: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          '& .MuiButtonBase-root': {
            height: 25,
            fontWeight: 500,
          },
        },
      }}
    >
      <Accordion
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Paid />
          <Typography>Payment</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '.MuiChip-root': {
              bgcolor: 'var(--secondary-background-color)',
              color: 'var(--primary-text-color)',
            },
            '& .MuiChip-root.selected': {
              bgcolor: 'var(--primary-color)',
              '&:hover': {
                bgcolor: 'var(--primary-color)',
              },
            },
          }}
        >
          <Chip
            label={'Crypto'}
            onClick={() => handleStatusChange(PaymentMethodStatus.CRYPTO)}
            className={cx({
              selected: paymentMethod.includes(PaymentMethodStatus.CRYPTO),
            })}
          />
          <Chip
            label={'USD'}
            onClick={() => handleStatusChange(PaymentMethodStatus.FIAT)}
            className={cx({
              selected: paymentMethod.includes(PaymentMethodStatus.FIAT),
            })}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PaymentFilter;
