import iconArt from 'assets/svgs/rainbow.svg';
import iconCollectibles from 'assets/svgs/bear.svg';
import iconSports from 'assets/svgs/soccerball.svg';
import iconUtility from 'assets/svgs/tools.svg';
import iconTrading from 'assets/svgs/cardboard.svg';
import iconVirtual from 'assets/svgs/monster.svg';

export const GroupFilters = [
  {
    value: 'all',
    label: 'All Items',
  },
  {
    value: 'single',
    label: 'Single Items',
  },
  // {
  //   value: 'bundle',
  //   label: 'Bundles',
  // },
];

export const Categories = [
  {
    id: 0,
    icon: iconArt,
    label: 'Art',
  },
  {
    id: 1,
    icon: iconCollectibles,
    label: 'Collectibles',
  },
  {
    id: 2,
    icon: iconSports,
    label: 'Sports',
  },
  {
    id: 3,
    icon: iconUtility,
    label: 'Utility',
  },
  {
    id: 4,
    icon: iconTrading,
    label: 'Trading Cards',
  },
  {
    id: 5,
    icon: iconVirtual,
    label: 'Virtual Worlds',
  },
];

export const SortByOptions = [
  {
    id: 'createdAt',
    label: 'Recently Created',
  },
  {
    id: 'oldest',
    label: 'Oldest',
  },
  {
    id: 'listedAt',
    label: 'Recently Listed',
  },
  {
    id: 'soldAt',
    label: 'Recently Sold',
  },
  {
    id: 'saleEndsAt',
    label: 'Ending Soon',
  },
  {
    id: 'price',
    label: 'Highest Price',
  },
  {
    id: 'cheapest',
    label: 'Lowest Price',
  },
  {
    id: 'lastSalePrice',
    label: 'Highest Last Sale',
  },
  {
    id: 'views',
    label: 'Mostly Viewed',
  },
];

export const FilterNFTStatus = {
  PENDING_APPROVAL: 'PendingApproval',
  APPROVED_BY_AGENT_AND_ADMIN: 'ApprovedAgentAdmin',
  APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN: 'ApprovedAgentDeniedAdmin',
  MINTED: 'Minted',
  APPROVED_BY_AGENT: 'ApprovedAgent',
  DENIED_BY_AGENT: 'DeniedAgent',
  PRE_MINT: 'PreMint',
  MINING_PENDING: 'MiningPending',
  PAYING_FEE_PENDING: 'PayingFeePending',
  APPROVE_MINTING_PENDING: 'ApproveMintingPending',
  BURNED: 'Burned',
  ERROR_MINTING_TX: 'ErrorMintingTx',
};

export const FilterStatus = {
  BUY_NOW: 'buyNow',
  HAS_BIDS: 'hasBids',
  HAS_OFFERS: 'hasOffers',
  ON_AUCTION: 'onAuction',
  ...FilterNFTStatus,
};

export const PaymentMethodStatus = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
};
export const FilterStatusFullName = {
  BUY_NOW: 'Buy Now',
  HAS_BIDS: 'Has Bids',
  HAS_OFFERS: 'Has Offers',
  ON_AUCTION: 'On Auction',
  PENDING_WHITELIST: 'Approval Process in Progress. Please wait',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED_BY_AGENT_AND_ADMIN: 'Approved By Agent And Admin',
  APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN:
    'Approved By Agent And Denied By Admin',
  MINTED: 'Minted',
  APPROVED_BY_AGENT: 'Approved By Agent',
  DENIED_BY_AGENT: 'Denied By Agent',
  PRE_MINT: 'PreMint',
  MINING_PENDING: 'Pending Mint',
  PAYING_FEE_PENDING: 'Paying Fee Pending',
  APPROVE_MINTING_PENDING: 'Approve Minting Pending',
  BURNED: 'Burned',
  ERROR_MINTING_TX: 'Processing Error',
};

export const FilterStatusFullNameMap = new Map([
  [FilterStatus.BUY_NOW, FilterStatusFullName.BUY_NOW],
  [FilterStatus.HAS_BIDS, FilterStatusFullName.HAS_BIDS],
  [FilterStatus.HAS_OFFERS, FilterStatusFullName.HAS_OFFERS],
  [FilterStatus.ON_AUCTION, FilterStatusFullName.ON_AUCTION],
  [FilterStatus.PENDING_APPROVAL, FilterStatusFullName.PENDING_APPROVAL],
  [
    FilterStatus.APPROVED_BY_AGENT_AND_ADMIN,
    FilterStatusFullName.APPROVED_BY_AGENT_AND_ADMIN,
  ],
  [
    FilterStatus.APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN,
    FilterStatusFullName.APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN,
  ],
  [FilterStatus.MINTED, FilterStatusFullName.MINTED],
  [FilterStatus.APPROVED_BY_AGENT, FilterStatusFullName.APPROVED_BY_AGENT],
  [FilterStatus.DENIED_BY_AGENT, FilterStatusFullName.DENIED_BY_AGENT],
  [FilterStatus.PRE_MINT, FilterStatusFullName.PRE_MINT],
  [FilterStatus.MINING_PENDING, FilterStatusFullName.MINING_PENDING],
  [FilterStatus.PAYING_FEE_PENDING, FilterStatusFullName.PAYING_FEE_PENDING],
  [
    FilterStatus.APPROVE_MINTING_PENDING,
    FilterStatusFullName.APPROVE_MINTING_PENDING,
  ],
  [FilterStatus.BURNED, FilterStatusFullName.BURNED],
  [FilterStatus.ERROR_MINTING_TX, FilterStatusFullName.ERROR_MINTING_TX],
]);

const FilterConstants = {
  UPDATE_STATUS_FILTER: 'UPDATE_STATUS_FILTER',
  UPDATE_PAYTOKEN_FILTER: 'UPDATE_PAYTOKEN_FILTER',
  UPDATE_PAYMENT_METHOD_FILTER: 'UPDATE_PAYMENT_METHOD_FILTER',
  UPDATE_COMMUNITY_FILTER: 'UPDATE_COMMUNITY_FILTER',
  UPDATE_LICENSE_FILTER: 'UPDATE_LICENSE_FILTER',
  RESET_STATUS_FILTER: 'RESET_STATUS_FILTER',
  RESET_PAYTOKEN_FILTER: 'RESET_PAYTOKEN_FILTER',
  RESET_COMMUNITY_FILTER: 'RESET_COMMUNITY_FILTER',
  UPDATE_COLLECTIONS_FILTER: 'UPDATE_COLLECTIONS_FILTER',
  UPDATE_CATEGORIES_FILTER: 'UPDATE_CATEGORIES_FILTER',
  RESET_CATEGORIES_FILTER: 'RESET_CATEGORIES_FILTER',
  UPDATE_GROUP_TYPE_FILTER: 'UPDATE_GROUP_TYPE_FILTER',
  UPDATE_SORT_BY_FILTER: 'UPDATE_SORT_BY_FILTER',
  UPDATE_SEARCH_PHRASE: 'UPDATE_SEARCH_PHRASE',
};

export default FilterConstants;
