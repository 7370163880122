import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useApi } from 'api';
import {
  Popover,
  Box,
  useTheme,
  useMediaQuery,
  ListItem,
  List,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Radio,
} from '@mui/material';
import styles from './styles.module.scss';
import cx from 'classnames';
import toast from 'utils/toast';
import UploadIcon from '@mui/icons-material/Upload';

import audiofile from 'assets/imgs/audio.png';
import otherfile from 'assets/imgs/otherfile.png';
import { DeleteOutline } from '@mui/icons-material';
// import imageCompression from 'browser-image-compression';
import { getFileType } from 'utils/getFileType';
import { ClipLoader } from 'react-spinners';
import { isImageFile } from 'utils';
import IconProhibited from 'assets/icons/iconProhibited';
import IconWaiting from '@mui/icons-material/AccessTime';
import { ASSET_TYPES } from 'constants/asset.constants';

const acceptImages = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/gif',
  'image/webp',
  'image/avif',
];
//const acceptMultile = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'];
const acceptMultipleFiles = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/gif',
  'image/webp',
  'image/avif',
  'video/mp4',
  'video/quicktime',
  'audio/mpeg',
  'audio/wav',
  'audio/x-wav',
  'audio/flac',
  'audio/aiff',
  'video/mp4; codecs="alac"',
  'audio/ogg',
  'audio/aac',
  'audio/AMR',
  'audio/x-ms-wma',
];
export const MAX_FILES = 5;

const InputMedia = ({
  changeFunction,
  assetType,
  setProgressUpload,
  waitingUpload,
  multiple = true,
  onlyImages = false,
  acceptedImages = [],
  data,
  removeCb,
  photosLength = 0,
  disabled = false,
  hasError = false,
  assetsToCheck = [],
  selectedImage = undefined,
  openImageSelection = false,
  setSelectedImage,
}) => {
  const location = useLocation();
  const [lastLocationKey, setLastLocationKey] = useState(location.key);
  const theme = useTheme();
  const isMountedRef = useRef(true);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const authToken = useSelector(state => state.Auth.authToken);
  const imageRef = useRef();
  const { uploadFile, setAssetUploaded } = useApi();
  const [uploadedFiles, setUploadedFiles] = useState(0);

  const deleteImageRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'delete-popover' : undefined;

  useEffect(() => {
    setUploadedFiles(photosLength);

    return () => {
      isMountedRef.current = false;
    };
  }, [photosLength]);

  const handleBeforeUnload = useCallback(
    e => {
      if (isUploading) {
        e.preventDefault();
        // May apply in some borwsers
        e.returnValue = 'Upload in progress. Are you sure you want to leave?';
      }
    },
    [isUploading]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    if (location.key !== lastLocationKey) {
      setLastLocationKey(location.key);
      // Here you can perform any cleanup or state reset needed when navigating within the app
    }
  }, [location, lastLocationKey]);

  const progressBarCb = fileName => newValue => {
    if (!isMountedRef.current) return;

    if (!fileName.includes('watermark')) {
      setProgressUpload(prev => {
        return { ...prev, [fileName]: newValue };
      });
    }
  };

  const completeCb = file => async response => {
    if (response) {
      if (!isMountedRef.current) return;

      const asset = response;

      // set asset uploaded
      const { errors } = await setAssetUploaded(authToken, asset.assetId);

      if (!errors) {
        asset.uploaded = true;
        asset.type = assetType;

        if (!isMountedRef.current) return;

        changeFunction(asset);
        // Upload completed
        setIsUploading(false);
      }
    } else {
      toast('error', `${file.name} is invalid`);
    }

    setProgressUpload(prev => {
      const afterUpload = Object.keys(prev)
        .filter(k => k !== file.name && k !== `watermark${file.name}`)
        .reduce((obj, key) => {
          obj[key] = prev[key];
          return obj;
        }, {});
      return afterUpload;
    });
  };

  const onDrop = useCallback(
    async files => {
      // Check for files greater than 100MB
      const acceptedFiles = files.filter(file => {
        if (file.size > 100 * 1024 * 1024) {
          toast('error', 'File is bigger than 100MB!');
          return false;
        }
        return true;
      });

      if (acceptedFiles.length < 1) return;

      // Start uploading
      setIsUploading(true);

      setProgressUpload(prev => {
        const uploadingBars = acceptedFiles.reduce((obj, file) => {
          obj[file.name] = 0;
          return obj;
        }, {});
        return { ...prev, ...uploadingBars };
      });

      acceptedFiles.map(async file => {
        // let convertedFile = file;

        if (
          (assetType === ASSET_TYPES.PHOTOS &&
            file.type &&
            file.type.includes('image')) ||
          assetType === ASSET_TYPES.THUMBNAIL_IMAGE
        ) {
          // if (file.size > 700000) {
          //   const options = {
          //     maxSizeMB: 1,
          //     maxWidthOrHeight: 1920,
          //     useWebWorker: true,
          //     fileType: file.type,
          //   };
          //   const compressedFile = await imageCompression(file, options);
          //   convertedFile = new File([compressedFile], file.name, {
          //     type: file.type,
          //   });
          // }
        }

        return uploadFile(
          authToken,
          assetType,
          file,
          progressBarCb(file.name),
          completeCb(file)
        );
      });
    },
    [authToken]
  );
  const maxFiles = uploadedFiles >= MAX_FILES ? 1 : MAX_FILES - uploadedFiles;

  const handlerRejectedFiles = fileRejections => {
    if (fileRejections?.length >= maxFiles) {
      toast('warning', 'Max number of downloaded files');
    } else {
      toast('error', fileRejections[0].errors[0].message);
    }
  };
  const handleMobileUpload = () => {
    imageRef.current?.click();
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: onlyImages
      ? acceptedImages?.length > 0
        ? acceptedImages.join(', ')
        : acceptImages.join(', ')
      : acceptMultipleFiles
          .concat(
            assetType === ASSET_TYPES.FRONT_COVER && data.length === 0
              ? ['application/zip', '.zip']
              : []
          )
          .join(', '),
    onDrop: files => {
      onDrop(files);
      // Reset input value
      imageRef.current.value = null;
    },
    onDropRejected: handlerRejectedFiles,
    noClick: true,
    multiple,
    maxFiles: multiple ? maxFiles : 1,
  });

  const addPhotoButton = () =>
    multiple &&
    !waitingUpload && (
      <div
        className={cx(styles.smallImage, styles.addPhotoButton)}
        onClick={() => imageRef.current?.click()}
      >
        <p className={cx(styles.iconSubtitle)}>+</p>
        <p className={cx(styles.uploadsubtitle)}>
          {assetType === ASSET_TYPES.PHOTOS
            ? 'Add Media Files'
            : assetType === ASSET_TYPES.FRONT_COVER
            ? 'Add Assets'
            : 'Add Files'}
        </p>
      </div>
    );

  const assetHasError = useCallback(
    asset => {
      const exists = assetsToCheck.some(
        a =>
          asset.assetId === a.assetId &&
          (asset?.failed ||
            asset?.nsfw ||
            asset?.nsfwDetected ||
            asset?.likely ||
            asset?.likelyDetected ||
            a?.failed ||
            a?.nsfw ||
            a?.nsfwDetected ||
            a?.likely ||
            a?.likelyDetected)
      );
      return exists;
    },
    [assetsToCheck]
  );

  const assetLoading = useCallback(
    asset => {
      const exists =
        !asset.parentId &&
        assetsToCheck.some(
          a => a.status === 'pending' && asset.assetId === a.assetId
        );
      return exists;
    },
    [assetsToCheck]
  );

  const isNSFW = useCallback(
    asset => {
      const exists = assetsToCheck.some(
        a => (a?.nsfw || a?.nsfwDetected) && asset.assetId === a.assetId
      );
      return asset?.nsfw || exists;
    },
    [assetsToCheck]
  );

  const isLikelyNSFW = useCallback(
    asset => {
      const exists = assetsToCheck.some(
        a => (a?.likely || a.likelyDetected) && asset.assetId === a.assetId
      );
      return asset?.likely || exists;
    },
    [assetsToCheck]
  );

  const renderInput = () => {
    return (
      <>
        <div
          className={cx(styles.board, waitingUpload && styles.waitingUpload)}
          onClick={() => {
            if (data.length === 0) {
              imageRef.current?.click();
            }
          }}
        >
          <div
            {...getRootProps({
              className: cx(
                styles.uploadCont,
                !data.length && styles.emtyCont,
                data.length > 0 && styles.labelEmptyCont
              ),
            })}
          >
            <input
              {...getInputProps()}
              ref={imageRef}
              disabled={waitingUpload || disabled}
            />
            {data.length > 0 ? (
              assetType === ASSET_TYPES.THUMBNAIL_IMAGE ? (
                renderImages([data[0]])
              ) : (
                renderImages(data)
              )
            ) : (
              <>
                <div className={styles.uploadFile}>
                  <div
                    className={cx(
                      styles.iconContainer,
                      hasError && styles.iconHasError,
                      disabled && styles.disable
                    )}
                  >
                    <UploadIcon />
                  </div>
                  <div className={styles.iconText}>Upload file</div>
                </div>
              </>
            )}
          </div>
        </div>
        {renderPopover()}
      </>
    );
  };

  const renderInputMobile = () => {
    return (
      <>
        {(data.length === 0 || multiple) && (
          <div
            {...getRootProps({
              className: cx(
                styles.uploadCont,
                !data.length > 0 && styles.emtyCont,
                data.length > 0 && styles.labelEmptyCont
              ),
            })}
          >
            <input
              {...getInputProps()}
              ref={imageRef}
              disabled={waitingUpload || disabled}
            />
            <div className={styles.uploadFile} onClick={handleMobileUpload}>
              <div
                className={cx(
                  styles.iconContainer,
                  hasError && styles.iconHasError
                )}
              >
                <UploadIcon />
              </div>
              <div className={styles.iconText}>Upload file</div>
            </div>
          </div>
        )}
        {data.length > 0 ? (
          assetType === ASSET_TYPES.THUMBNAIL_IMAGE ? (
            renderImagesMobile([data[0]])
          ) : (
            renderImagesMobile(data)
          )
        ) : (
          <></>
        )}
        {renderPopover()}
      </>
    );
  };

  const renderPopover = () => {
    return (
      <>
        <Popover
          id={idPopover}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <p className={cx(styles.popoverLabel)}>
            Are you sure you want to delete this file?
          </p>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <div
              className={cx(styles.button)}
              onClick={() => {
                removeCb(deleteImageRef.current);
                handleClosePopover();
              }}
            >
              Yes
            </div>
            <div
              className={cx(styles.cancelButton)}
              onClick={handleClosePopover}
            >
              Cancel
            </div>
          </Box>
        </Popover>
      </>
    );
  };

  const checkIfTheAssetIsReady = (assetType, asset) => {
    switch (assetType) {
      case ASSET_TYPES.THUMBNAIL_IMAGE:
        return data.length === 2;
      case ASSET_TYPES.FRONT_COVER:
        return isImageFile(asset?.url)
          ? asset?.relatedAssets && asset.relatedAssets.length > 0
          : true;
      default:
        return !waitingUpload;
    }
  };

  const renderAdditionalImages = (el, fileType) => {
    if (openImageSelection) {
      console.log('!assetHasError(el)', !assetHasError(el), el);
      if (
        fileType === 'image' &&
        checkIfTheAssetIsReady(assetType, el) &&
        !assetHasError(el)
      ) {
        return (
          <Radio
            checked={selectedImage == el.assetId}
            onChange={e => setSelectedImage(e.target.value)}
            value={el.assetId}
            name="radio-buttons"
            className={styles.radio}
          />
        );
      } else {
        return <></>;
      }
    }

    return (
      <>
        {((!el.parentId && assetLoading(el)) ||
          !checkIfTheAssetIsReady(assetType, el)) && (
          <div className={styles.loader}>
            <ClipLoader color="#FFF" size={26} />
          </div>
        )}
        <div className={cx(styles.deleteIcon, multiple && styles.smallIcon)}>
          <DeleteOutline fontSize="large" />
        </div>
      </>
    );
  };

  const renderImages = _data => {
    return (
      <>
        <div className={styles.additionalImages}>
          {_data.map(el => {
            const fileType = getFileType(el?.url);
            return (
              <div
                key={el.assetId}
                className={cx(
                  styles.deleteImage,
                  multiple && styles.smallImage,
                  assetHasError(el) && styles.hasError
                )}
                onClick={e => {
                  e.stopPropagation();
                  if (openImageSelection) {
                    return;
                  }

                  deleteImageRef.current = el.assetId;
                  handlePopover(e);
                }}
              >
                {isNSFW(el) ? (
                  <div className={styles.prohibited}>
                    <IconProhibited />
                  </div>
                ) : (
                  isLikelyNSFW(el) && (
                    <div className={styles.prohibited}>
                      <IconWaiting color="warning" />
                    </div>
                  )
                )}
                {renderAdditionalImages(el, fileType)}
                {fileType === 'video' ? (
                  <video
                    src={el.fullUrl}
                    className={cx(
                      multiple ? styles.smallIcon : styles.videoIcon
                    )}
                  />
                ) : (
                  <img
                    src={
                      fileType === 'image'
                        ? el?.fullUrl || el?.url
                        : fileType === 'audio'
                        ? audiofile
                        : otherfile
                    }
                    className={cx(multiple && styles.smallIcon)}
                  />
                )}
              </div>
            );
          })}
          {MAX_FILES > _data.length && addPhotoButton()}
        </div>
      </>
    );
  };

  const renderImagesMobile = data => {
    return (
      <>
        <div className={cx(styles.listItemClass)}>
          <List disablePadding className={styles.listAssetMobile}>
            {data.map(el => {
              const fileType = getFileType(el?.url);
              return (
                <ListItem
                  key={el?.url}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 0,
                    position: 'relative !important',
                  }}
                >
                  {isNSFW(el) ? (
                    <div className={styles.prohibitedMobile}>
                      <IconProhibited />
                    </div>
                  ) : (
                    isLikelyNSFW(el) && (
                      <div className={styles.prohibitedMobile}>
                        <IconWaiting color="warning" />
                      </div>
                    )
                  )}
                  {assetLoading(el) && (
                    <div className={styles.loader}>
                      <ClipLoader color="#FFF" size={26} />
                    </div>
                  )}
                  {openImageSelection &&
                    fileType === 'image' &&
                    checkIfTheAssetIsReady(assetType, el) &&
                    !assetHasError(el) && (
                      <Radio
                        checked={selectedImage == el.assetId}
                        onChange={e => setSelectedImage(e.target.value)}
                        value={el.assetId}
                        name="radio-buttons"
                        className={styles.radio}
                      />
                    )}

                  {fileType === 'video' ? (
                    <video
                      src={el.fullUrl}
                      className={cx(
                        assetHasError(el) && styles.hasError,
                        styles.smallIconMobile
                      )}
                    />
                  ) : fileType === 'image' ? (
                    <img
                      src={
                        fileType === 'image'
                          ? el?.fullUrl || el?.url
                          : fileType === 'audio'
                          ? audiofile
                          : otherfile
                      }
                      className={cx(
                        assetHasError(el) && styles.hasError,
                        styles.smallIconMobile
                      )}
                    />
                  ) : (
                    <ListItemText
                      primary={el?.url}
                      disableTypography
                      className={cx(styles.listItemText)}
                    />
                  )}

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={e => {
                        e.stopPropagation();
                        deleteImageRef.current = el.assetId;
                        handlePopover(e);
                        // removeCb(el.assetId);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </div>
      </>
    );
  };

  return (
    <div>
      <Prompt
        when={isUploading}
        message="You have an upload in progress. Are you sure you want to leave?"
      />
      {!isMobile ? renderInput() : renderInputMobile()}
    </div>
  );
};

export default InputMedia;
