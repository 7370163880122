import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';
import Footer from '../../../components/Footer';

const RefundDisputePolicy = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="February 07, 2025"
          title="Refund and Dispute Policy"
          description=""
          url="/files/sales, refund and dispute policy.pdf"
        >
          <div className={styles.authForm}>
            <h2>Final Sale & No Refunds</h2>
            <p>
              All sales on our platform are{' '}
              <span className={styles.bold}> final and non-refundable</span>.
              Once a purchase is completed, it cannot be canceled, returned, or
              refunded, except where required by applicable law.
            </p>
            <h2>Chargebacks & Dispute Resolution</h2>
            <p>
              Disputes related to transactions, including chargebacks, will be
              handled exclusively through{' '}
              <span className={styles.bold}>
                Stripe’s dispute resolution process
              </span>
              . By using our platform, buyers acknowledge and agree that:
            </p>
            <ul>
              <li>
                Any disputes regarding transactions must be directed to Stripe.
              </li>
              <li>
                We do not process or mediate disputes between buyers and
                sellers.
              </li>
              <li>
                Buyers are responsible for reviewing product descriptions,
                seller policies, and ensuring their satisfaction prior to making
                a purchase.
              </li>
            </ul>
            <h2>Payment Processing & Compliance</h2>
            <p>
              All payments are processed through{' '}
              <span className={styles.bold}>Stripe</span>, and transactions must
              comply with Stripe’s Terms of Service and Acceptable Use Policy.
              By using our platform, buyers and sellers agree to:
            </p>
            <ul>
              <li>
                Adhere to <span className={styles.bold}>Stripe’s policies</span>{' '}
                regarding chargebacks, refunds, and disputes.
              </li>
              <li>Provide accurate billing and payment information.</li>
              <li>
                Acknowledge that unauthorized or fraudulent transactions may
                result in suspension or termination of account access.
              </li>
            </ul>
            <h2>Fraud Prevention & Security</h2>
            <p>To protect against fraud and unauthorized transactions:</p>
            <ul>
              <li>
                We reserve the right to investigate and report suspicious
                activity to Stripe and relevant authorities.
              </li>
              <li>
                Sellers must ensure that they meet all{' '}
                <span className={styles.bold}>
                  {' '}
                  Stripe compliance requirements
                </span>{' '}
                for accepting payments.
              </li>
              <li>
                Buyers should use valid payment methods and refrain from
                engaging in chargeback fraud.
              </li>
            </ul>
            <h2>Changes to the Sales Policy</h2>
            <p>
              We may update this policy at any time to reflect changes in our
              platform’s operations, legal requirements, or Stripe’s policies.
              Continued use of our platform after such updates constitutes
              acceptance of the revised policy.
            </p>
            <h2>Contact & Support</h2>
            <p>
              For questions regarding this policy, please contact{' '}
              <span className={styles.bold}>admin@minti.fun</span>. For
              disputes, please refer to Stripe’s dispute resolution process.
            </p>
          </div>
        </LicenseGrid>
      </div>
      <Footer />

      <FooterAction />
    </div>
  );
};
export default RefundDisputePolicy;
