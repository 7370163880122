import { gql } from '@apollo/client';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on UserDTO {
    userId
    email
    isActive
    isEmailConfirmed
    name
    bio
    avatar
    banner
    twitter
    instagram
    website
    is2fa
    isStripeKYCed
    isAdmin
    unredeemedPoint
    redeemedPoint
    userStatus {
      name
    }
    address
    isFollowing
    tradeAmount
    kycRequired
    userWallets {
      externalUserId
      address
      signature
      isVerified
      kycProcess
    }
    whitelistRequests {
      description
      isChecked
      rejectReason
    }
  }
`;

export const CORE_NFT_FIELDS = gql`
  fragment CoreNftFields on NftDTO {
    nftId
    userId
    user {
      userId
      email
      address
      isActive
      isEmailConfirmed
      name
      bio
      avatar
      banner
      twitter
      instagram
      website
      isFollowing
      userWallets {
        address
      }
    }
    artistId
    artist {
      userId
      email
      isActive
      isEmailConfirmed
      name
      bio
      avatar
      banner
      twitter
      instagram
      website
    }
    nftContractId
    mintTxHash
    name
    description
    nftStatusId
    nftStatus {
      name
    }
    nftAssets {
      assetId
      url
      assetTypeId
      status
      uploaded
      assetType {
        name
      }
      parentId
      relatedAssets {
        assetId
        assetType {
          name
        }
        url
      }
    }
    tags {
      tagId
      name
    }
    price
    lastSalePrice
    listedAt
    soldAt
    saleEndsAt
    payTokenId
    payToken {
      payTokenId
      icon
      name
      symbol
      address
      chainlinkProxyAddress
      decimals
      isMainnet
      price
      isDisabled
    }
    royalty
    categoryId
    category {
      categoryId
      type
      icon
      smallIcon
      label
    }
    subcategories {
      categoryId
      parentId
      type
    }
    metadataUri
    contractTypeId
    contractType {
      contractTypeId
      name
    }
    sellingStatusId
    sellingStatus {
      sellingStatusId
      name
    }
    likes
    views
    processing
    isLiked
    isFavorite
    isViewed
    createdAt
    attributes {
      attribute
      value
    }
    paymentMethod
  }
`;

export const CORE_OFFER_FIELDS = gql`
  fragment CoreOfferFields on OfferDTO {
    offerId
    creator {
      name
      avatar
      userId
      userWallets {
        address
      }
    }
    creatorId
    paymentToken
    price
    deadline
    isExpired
  }
`;

export const CORE_TRADE_HISTORY_FIELDS = gql`
  fragment CoreTradeHistoryFields on TradeHistoryDTO {
    tradeHistoryId
    saleDate
    buyer {
      avatar
      name
    }
    seller {
      avatar
      name
    }
    paymentToken
    price
    priceUsd
    txHash
  }
`;

export const CORE_COMMENT_FIELDS = gql`
  fragment CoreCommentFields on CommentDTO {
    commentId
    comment
    nftId
    userId
    user {
      userId
      name
      address
      avatar
    }
    parentId
    replies
    upvotes
    downvotes
    upvoted
    downvoted
    edited
    createdAt
  }
`;
