/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { formatNumber } from 'utils';
import PriceInput from 'components/PriceInput';
import { StyledButton } from 'components/StyledComponents';
import { useApi } from 'api';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';
import { useHistory } from 'react-router-dom';

const SellModal = ({
  visible,
  onClose,
  onSell,
  authToken,
  startPrice,
  payToken,
  confirming,
  paymentMethod,
  isStripeKYCed,
  isCreator,
  walletAddress,
}) => {
  const history = useHistory();
  const { fetchPayTokens } = useApi();
  const [tokens, setTokens] = useState();
  const [price, setPrice] = useState('');
  const [focused, setFocused] = useState(false);
  const [selected, setSelected] = useState(payToken ? payToken.address : '');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('fiat');
  const [openSelect, setOpenSelect] = useState(false);
  const [inputError, setInputError] = useState(null);

  const fetchTokens = async _authToken => {
    const { data } = await fetchPayTokens(_authToken);

    if (data) {
      const convertedData = data.reduce(
        (a, v) => ({ ...a, [v.address]: v }),
        {}
      );
      setTokens(convertedData);
    }
  };
  useEffect(() => {
    setPrice(startPrice > 0 ? startPrice.toString() : '');
    if (paymentMethod) {
      setSelectedPaymentMethod(paymentMethod);
    }
  }, [visible, paymentMethod]);

  useEffect(() => {
    if (payToken) {
      setSelected(payToken?.address);
    }
  }, [payToken]);

  useEffect(() => {
    fetchTokens(authToken);
  }, [authToken]);
  const handleSellItem = () => {
    onSell(
      selectedPaymentMethod === 'crypto' ? tokens[selected] : undefined,
      price,
      paymentMethod || selectedPaymentMethod,
      authToken
    );
  };

  const validateInput = () => {
    if (!price || price.length === 0 || parseFloat(price) == 0) return false;
    if (selectedPaymentMethod === 'crypto' && !selected) return false;
    return true;
  };

  const handlePaymentMethodSelection = method => {
    if (!paymentMethod) {
      setSelectedPaymentMethod(method);
    }
  };

  return (
    <Modal
      visible={visible}
      title={startPrice > 0 ? 'Update Your Listing' : 'Sell Your Asset'}
      onClose={() => {
        onClose();
        setSelected(payToken?.address);
        setPrice(startPrice);
      }}
      submitDisabled={
        confirming ||
        !validateInput() ||
        inputError ||
        !selectedPaymentMethod ||
        (selectedPaymentMethod === 'fiat' && !isStripeKYCed) ||
        (selectedPaymentMethod === 'crypto' && !walletAddress)
      }
      submitLabel={
        !selectedPaymentMethod || selectedPaymentMethod === 'crypto' ? (
          confirming ? (
            <ClipLoader color="#FFF" size={16} />
          ) : startPrice > 0 ? (
            'Update Price'
          ) : (
            'List Your Asset'
          )
        ) : confirming ? (
          <ClipLoader color="#FFF" size={16} />
        ) : startPrice > 0 ? (
          'Update Price'
        ) : (
          'List Your Asset'
        )
      }
      onSubmit={() => {
        handleSellItem();
      }}
      showCloseIcon={false}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Payment Method</div>
        <div className={styles.buttonsContainer}>
          <div className={styles.selections}>
            {isCreator && !paymentMethod ? (
              <>
                {/* <StyledButton
                  type="button"
                  secondary="true"
                  className={cx(
                    selectedPaymentMethod === 'crypto' ? styles.active : ''
                  )}
                  disabled={Boolean(paymentMethod)}
                  onClick={() => handlePaymentMethodSelection('crypto')}
                >
                  Crypto
                </StyledButton> */}
                <StyledButton
                  type="button"
                  secondary="true"
                  className={cx(
                    selectedPaymentMethod === 'fiat' ? styles.active : ''
                  )}
                  disabled={Boolean(paymentMethod)}
                  onClick={() => handlePaymentMethodSelection('fiat')}
                >
                  USD
                </StyledButton>
              </>
            ) : (
              <StyledButton
                type="button"
                secondary="true"
                className={styles.active}
                onClick={() => handlePaymentMethodSelection('fiat')}
                disabled
              >
                {selectedPaymentMethod === 'crypto' ? 'Crypto' : 'USD'}
              </StyledButton>
            )}
          </div>
          {!isStripeKYCed && selectedPaymentMethod === 'fiat' && (
            <div className={styles.sellerAccount}>
              Please create a seller account
              <button
                onClick={() =>
                  history.push('/profile/payment-setup?modal=become-seller')
                }
              >
                here
              </button>
            </div>
          )}
          {!walletAddress && selectedPaymentMethod === 'crypto' && (
            <div className={styles.sellerAccount}>
              Please connect your wallet
              <button onClick={() => history.push('/profile/connect-wallet')}>
                here
              </button>
            </div>
          )}
        </div>
        <div className={styles.formLabel}>Price</div>
        <div className={cx(styles.formInputCont, focused && styles.focused)}>
          {selectedPaymentMethod === 'crypto' && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              defaultValue={selected}
              label="Value"
              onChange={e => setSelected(e.target.value)}
              onOpen={e => {
                if (e.key !== 'Enter') {
                  setOpenSelect(true);
                }
              }}
              onClose={() => {
                setOpenSelect(false);
              }}
              open={openSelect}
              className={styles.select}
              sx={{
                '.MuiSelect-select': {
                  display: 'flex',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {tokens &&
                Object.keys(tokens).map(address => (
                  <MenuItem
                    value={address}
                    key={address}
                    className={styles.selectedToken}
                  >
                    <img
                      src={tokens[address]?.icon}
                      className={styles.tokenIcon}
                    />
                    <div className={styles.tokenSymbol}>
                      {tokens[address]?.symbol}
                    </div>
                  </MenuItem>
                ))}
            </Select>
          )}
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            decimals={selected?.decimals || 0}
            value={'' + price}
            onChange={setPrice}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={
              confirming ||
              !selectedPaymentMethod ||
              (selectedPaymentMethod === 'fiat' && !isStripeKYCed)
            }
            onInputError={setInputError}
          />
          {selectedPaymentMethod === 'crypto' &&
            selected &&
            tokens[selected] && (
              <div className={styles.usdPrice}>
                {!isNaN(tokens[selected]?.price) &&
                tokens[selected]?.price !== null ? (
                  `$${formatNumber(
                    (
                      (parseFloat(price) || 0) * tokens[selected]?.price
                    ).toFixed(2)
                  )}`
                ) : (
                  <Skeleton width={100} height={24} />
                )}
              </div>
            )}
        </div>
        <InputError text={inputError} />
      </div>
    </Modal>
  );
};

export default SellModal;
