import React from 'react';
import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePaymentModal = ({ visible, clientSecret, onClose }) => {
  return (
    <Modal
      visible={visible && clientSecret}
      title={''}
      onClose={() => {
        onClose();
      }}
      submitDisabled={false}
      onSubmit={() => {}}
      showCloseIcon
    >
      <div className={styles.stripeModal}>
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Modal>
  );
};

export default StripePaymentModal;
